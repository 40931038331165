import React from "react";
import market from "../../../assets/icons/market-share 1.svg";
import group from "../../../assets/icons/Group 34.svg";
import connection from "../../../assets/icons/006-connection-1 1.svg";
import vidEng from "../../../assets/CBIO Animation Videos/Affiliate Prog.mp4"
import vidEs from "../../../assets/CBIO Animation Videos Spanish/Affiliate Program Spanish.mp4"

function Affliate({ locale }) {
  return (
    <div className="bg-[#D9D9D9] bg-opacity-90 text-[#2775CA] p-clamp-padding-feature">
      <div className="text-3xl font-semibold text-center">
        {locale === "es"
          ? "Programa de socios afiliados"
          : "Affiliate Partner Program"}
      </div>
      <div className="mt-4 text-lg font-semibold text-center">
        {locale === "es"
          ? "Gana el 25% de todo el dinero que recibe cryptobots.io de las personas que ¡Refiérase desde el principio!"
          : "Earn 25% from all the money cryptobots.io receives from the people you refer, right from the start!"}
      </div>
      <div className="flex flex-col-reverse items-center justify-between gap-8 mt-8 md:gap-12 xl:flex-row">
        <div className="flex flex-col w-[80%] sm:w-1/2 gap-12 py-12 lg:w-1/3">
          <div className="flex flex-col gap-4">
            <img src={market} alt="guide" className="mx-auto" />
            <span className="text-lg">
              {locale === "es"
                ? "Tan pronto como tus referidos paguen su parte de las ganancias, acreditamos tu cuenta con el 25% del monto total para que puedas retirarlo inmediatamente desde tu panel de control."
                : "As soon as your referrals pay their profit share,  we credit your account with 25% of the total amount for you to withdraw immediately right from your dashboard!"}
            </span>
          </div>

          <div className="flex flex-col gap-4">
            <span className="flex justify-between">
              <img src={group} alt="guide" className="w-" />
              <img src={connection} alt="guide" className="-translate-y-8" />
            </span>
            <span className="text-lg">
              {locale === "es"
                ? "No es necesario ser un usuario activo de nuestros servicios para participar.¡Simplemente regístrate para comenzar a ganar!"
                : "There is no need to be an active user of our services to participate. Simply register an account to start earning!"}
            </span>
          </div>

        </div>

        <div className="w-full lg:w-2/3">
          <div className="w-full mt-4 h-[300px] sm:h-[500px] bg-[#D9D9D9] rounded-3xl overflow-hidden relative">
            <video
              src={locale === 'es' ? vidEs : vidEng}
              controls
              className="absolute inset-0 object-cover w-full h-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Affliate;
