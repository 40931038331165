import React, { useEffect, useState } from "react";
import Wrapper from "../Dashboard/DashboardWrapper";
import bitcoin from "../../assets/icons/bitcoin.svg";
import green_arrow from "../../assets/icons/Green_arrow.svg";
import green_path from "../../assets/icons/path.svg";
import ethereum from "../../assets/icons/ethereum.svg";
import solana from "../../assets/icons/solana.svg";
import usdc from "../../assets/icons/usd-coin.svg";
import tether from "../../assets/icons/tether-usdt.svg";
import okx from "../../assets/icons/OKX.svg";
import kraken from "../../assets/icons/Kraken.svg";
import gate_io from "../../assets/icons/full-gate.svg";
import coinbase from "../../assets/icons/coinbase.svg";
import gemini from "../../assets/icons/gemini.svg";
import kucoin from "../../assets/icons/kucoin.svg";
import binance from "../../assets/icons/binance.svg";
import binance_us from "../../assets/icons/Binance-us.svg";
import bitget from "../../assets/icons/Bitget.svg";
import bybit from "../../assets/icons/bybit.svg";
import Card from "./ActiveCard";
import { toast } from "react-toastify";
import { useLoading, useLocalStorage } from "../../hooks";
import { FetchBots } from "../../API";
import { confirmSignIn } from "../../utilities";

export const cardItems = [
  {
    id: "1",
    bot: "Bitcoin bots",
    logo: [bitcoin],
    strategy: {
      name: "CB001",
      return: "1.5%",
      direction: "LONG",
      leverage: "1.0x",
    },
    wallets: [binance, okx, gate_io, bybit],
  },
  {
    id: "2",
    bot: "Bitcoin bots",
    logo: [bitcoin],
    strategy: {
      name: "CB002",
      return: "1.5%",
      direction: "LONG",
      leverage: "1.0x",
    },
    wallets: [binance, okx, gate_io, bybit],
  },
  {
    id: "3",
    bot: "Bitcoin bots",
    logo: [bitcoin],
    strategy: {
      name: "CB003",
      return: "1.5%",
      direction: "LONG",
      leverage: "1.0x",
    },
    wallets: [binance, okx, gate_io, bybit],
  },
  {
    id: "4",
    bot: "Bitcoin bots",
    logo: [bitcoin],
    strategy: {
      name: "CB004",
      return: "1.5%",
      direction: "LONG",
      leverage: "1.0x",
    },
    wallets: [binance, okx, gate_io, bybit],
  },
  {
    id: "5",
    bot: "Bitcoin bots",
    logo: [bitcoin],
    strategy: {
      name: "CB005",
      return: "1.5%",
      direction: "LONG",
      leverage: "1.0x",
    },
    wallets: [binance, okx, gate_io, bybit],
  },

  {
    id: "6",
    bot: "Ethereum bots",
    logo: [ethereum],
    strategy: {
      name: "CB006",
      return: "1.5%",
      direction: "LONG",
      leverage: "1.0x",
    },

    wallets: [binance, okx, bybit],
  },
  {
    id: "7",
    bot: "Ethereum bots",
    logo: [ethereum],
    strategy: {
      name: "CB007",
      return: "1.5%",
      direction: "LONG",
      leverage: "1.0x",
    },

    wallets: [binance, okx, bybit],
  },
  {
    id: "8",
    bot: "Ethereum bots",
    logo: [ethereum],
    strategy: {
      name: "CB008",
      return: "1.5%",
      direction: "LONG",
      leverage: "1.0x",
    },

    wallets: [binance, okx, bybit],
  },
  {
    id: "9",
    bot: "Ethereum bots",
    logo: [ethereum],
    strategy: {
      name: "CB009",
      return: "1.5%",
      direction: "LONG",
      leverage: "1.0x",
    },

    wallets: [binance, okx, bybit],
  },
  {
    id: "10",
    bot: "Ethereum bots",
    logo: [ethereum],
    strategy: {
      name: "CB010",
      return: "1.5%",
      direction: "LONG",
      leverage: "1.0x",
    },

    wallets: [binance, okx, bybit],
  },
  {
    id: "11",
    bot: "Solana bots",
    logo: [solana],
    strategy: {
      name: "CB011",
      return: "1.5%",
      direction: "LONG",
      leverage: "1.0x",
    },

    wallets: [okx, binance],
  },
  // {
  //   id: "12",
  //   bot: "Solana bots",
  //   logo: [solana],
  //   strategy: {
  //     name: "CB012",
  //     return: "1.5%",
  //     direction: "LONG",
  //     leverage: "1.0x",
  //   },

  //   wallets: [okx, binance],
  // },
  {
    id: "13",
    bot: "Solana bots",
    logo: [solana],
    strategy: {
      name: "CB013",
      return: "1.5%",
      direction: "LONG",
      leverage: "1.0x",
    },

    wallets: [okx, binance],
  },
  {
    id: "14",
    bot: "Solana bots",
    logo: [solana],
    strategy: {
      name: "CB014",
      return: "1.5%",
      direction: "LONG",
      leverage: "1.0x",
    },

    wallets: [okx, binance],
  },
  {
    id: "15",
    bot: "Solana bots",
    logo: [solana],
    strategy: {
      name: "CB015",
      return: "1.5%",
      direction: "LONG",
      leverage: "1.0x",
    },

    wallets: [okx, binance],
  },
  {
    id: "16",
    bot: "USD stable coin bots",
    logo: [tether, usdc],
    strategy: {
      name: "CB016",
      return: "1.5%",
      direction: "SOPT",
      leverage: "1.0x",
    },

    wallets: [
      binance,
      kraken,
      coinbase,
      gemini,
      bybit,
      okx,
      gate_io,
      kucoin,
      binance_us,
    ],
  },
  {
    id: "17",
    bot: "USD stable coin bots",
    logo: [tether, usdc],
    strategy: {
      name: "CB017",
      return: "1.5%",
      direction: "SOPT",
      leverage: "1.0x",
    },

    wallets: [
      binance,
      kraken,
      coinbase,
      gemini,
      bybit,
      okx,
      gate_io,
      kucoin,
      binance_us,
    ],
  },
  {
    id: "18",
    bot: "USD stable coin bots",
    logo: [tether, usdc],
    strategy: {
      name: "CB018",
      return: "1.5%",
      direction: "SOPT",
      leverage: "1.0x",
    },

    wallets: [
      binance,
      kraken,
      coinbase,
      gemini,
      bybit,
      okx,
      gate_io,
      kucoin,
      binance_us,
    ],
  },
];

function Active() {
  const [isLoading, startLoading, stopLoading] = useLoading();
  const [authToken, setAuthToken] = useLocalStorage("authToken", "");
  const [bots, setBots] = useState([]);

  const fetchBots = async () => {
    startLoading();
    const res = await FetchBots(authToken);
    console.log(res);
    if (res?.success) {
      setBots(res.bots);
    } else {
      confirmSignIn(res.message, window, setAuthToken);
      toast.error(res?.message || "Unable to fetch bots", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  useEffect(() => {
    fetchBots();
  }, []);

  return (
    <Wrapper className={"px-4 py-12 rounded-md relative bg-[#292C3C]"}>
      <div className="">
        <div className="px-4 mt-16 cursor-pointer">
          <span className="font-semibold whitespace-nowrap">All bots:</span>
        </div>
        <div className="flex-wrap flex gap-12 bg-[#292C3C] py-8 sm:px-6 px-4 rounded-xl ">
          {bots.map((item) => {
            let filter = cardItems.filter(
              (f) => f.strategy.name === item.strategy
            );
            return filter.map((card) => (
              <Card
                key={card.id}
                item={item}
                card={card}
                onClick={() =>
                  window.open(
                    `/dashboard/bot-stats?strategy=${card.strategy.name}&bot_id=${item?._id}&profit_coin=${item.profitCurrency}&exchange=${item.exchange}&deposit_coin=${item.depositCurrency}`,
                    "_self"
                  )
                }
              />
            ));
          })}
        </div>
      </div>

      {/* <div className="flex justify-end mr-4 ">{`Performance statistics >`}</div> */}
    </Wrapper>
  );
}

export default Active;
