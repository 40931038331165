import React, { useState } from "react";
import Wrapper from "./DashboardWrapper";
import { useLoading, useLocalStorage } from "../../hooks";
import { toast } from "react-toastify";
import Loader from "../Loader";
import { ChangePassword, ForgotPassword, SignUp } from "../../API";
import MainWrapper from "../MainWrapper";
import Header from "./Header";
import Sidebar from "./Sidebar";
import front_arrow from "../../assets/icons/front-arrow.png";
import { useLocation } from "react-router-dom";
import { confirmSignIn } from "../../utilities";

function Index() {
  const [isLoading, startLoading, stopLoading] = useLoading();

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // Access location object using useLocation hook
  const location = useLocation();

  // Parse the search string to extract query parameters
  const queryParams = new URLSearchParams(location.search);

  // Get individual query parameters by key
  const otp = queryParams.get("otp");

  const handleChangePassword = async () => {
    if (password !== confirmPassword) {
      toast.error("New password must match Confirm password", {
        position: "top-center",
      });
      return;
    }
    startLoading();
    const res = await ChangePassword({
      password,
      otp,
    });
    if (res?.success) {
      toast.success(res.message, { position: "top-center" });
    } else {
      toast.error(res.message, { position: "top-center" });
    }
    stopLoading();
  };

  return (
    <MainWrapper>
      <Header />
      <Sidebar active={{ id: "", href: "/dashboard" }} />
      <Wrapper className={"px-8 py-12 rounded-xl relative bg-[#292C3C]"}>
        <Loader isLoading={isLoading} />
        <div
          onClick={() => window.open("/dashboard", "_self")}
          className="flex items-center gap-4 cursor-pointer py-8"
        >
          {/* <img src={front_arrow} className="w-8 rotate-[180deg]" /> */}
          <span className="font-semibold">Change password</span>
        </div>
        <div className="">
          <div className="flex flex-col gap-4">
            <input
              placeholder="New password"
              className="py-1 px-2 bg-transparent focus:outline-none"
              value={password}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />

            <input
              placeholder="Confirm password"
              className="py-1 px-2 bg-transparent focus:outline-none"
              value={confirmPassword}
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <div className=" flex items-center gap-8 mt-12">
              <div
                onClick={() => handleChangePassword()}
                className="bg-green-dark flex items-center rounded-full px-4 py-2 text-lg cursor-pointer"
              >
                Submit
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </MainWrapper>
  );
}

export default Index;
