import React from "react";
import bitcoin from "../../../assets/icons/bitcoin.svg";
import green_arrow from "../../../assets/icons/Green_arrow.svg";
import green_path from "../../../assets/icons/path.svg";
import usdc from "../../../assets/icons/usd-coin.svg";
import tether from "../../../assets/icons/tether-usdt.svg";
import ethereum from "../../../assets/icons/ethereum.svg";
import solana from "../../../assets/icons/solana.svg";

export const USD_BOTS = ["CB003", "CB006", "CB009", "CB010", "CB011"];
const coins = [
  { name: "btc", value: bitcoin },
  { name: "eth", value: ethereum },
  { name: "sol", value: solana },
];

export const ColorCard = ({
  bg,
  titleColor,
  lightCircle,
  textLight,
  textDark,
  wallets,
  strategy,
  onClick,
  setShowModal,
}) => {
  const isAvailable = strategy.isAvailable;

  return (
    <div
      onClick={() => {
        onClick();
        setShowModal(true);
      }}
      className="w-[360px] "
    >
      <div
        className={`border-8 rounded-2xl border-[#62688F] text-md ${textLight} ${bg} py-2 px-2`}
      >
        <div className={`flex justify-between items-center`}>
          {/* <div
            onClick={onClick}
            className={`${isAvailable? "bg-[#292C3C]": "bg-[#2F3144]"} text-[10px] rounded-full w-[20%] whitespace-nowrap text-center py-1 uppercase my-1 text-green-light font-extrabold cursor-pointer hover:scale-105 transition-transform duration-300`}
          >
            Explore
          </div> */}

          <div className={`text-xl ${titleColor} font-semibold`}>
            {strategy?.name || ""}
          </div>
        </div>

        <div className="flex justify-between w-full">
          <div className={`w-full flex flex-col justify-between gap-1`}>
            <div
              className={`flex ${
                isAvailable ? lightCircle : "bg-[#12182236]"
              } ${textLight} w-full flex-col items-end rounded-xl p-2`}
            >
              <div className={`flex justify-between items-center w-full `}>
                <span className="text-[8px]">Strategy direction</span>
                <div className="flex items-center gap-1">
                  <span className="text-[8px]">{strategy.direction}</span>
                  <img
                    className={`${
                      strategy.direction.includes("LONG")
                        ? ""
                        : "rotate-[180deg]"
                    } w-2 h-2  ${
                      strategy.isAvailable ? "" : "filter grayscale"
                    }`}
                    src={green_arrow}
                    alt=""
                  />
                </div>
              </div>
              <div className={`flex justify-between items-center w-full `}>
                <span className="text-[8px]">Deposit denomination:</span>
                <span className="text-[8px] uppercase">
                  {strategy.deposit_coin}
                </span>
              </div>
              <div className={`flex justify-between items-center w-full `}>
                <span className="text-[8px]">Profits are settled in:</span>
                <span className="text-[8px] uppercase">
                  {strategy.profit_coin}
                </span>
              </div>
              <div className={`flex justify-between items-center w-full `}>
                <span className="text-[8px]">Minimum deposit:</span>
                <span className="text-[8px]">{strategy.minimum_deposit}</span>
              </div>
            </div>

            <div className="flex items-center justify-between w-full gap-2">
              <div className={`flex flex-col`}>
                <span className="text-[8px]">Market Type:</span>
                <div
                  className={`${
                    isAvailable ? "bg-[#292C3C]" : "bg-[#2F3144]"
                  } flex items-center rounded-xl px-3 py-1 text-[8px] ${textDark} font-semibold whitespace-nowrap`}
                >
                  {strategy.market_type}
                </div>
              </div>

              <div className={`flex flex-col`}>
                <span className="whitespace-nowrap text-[8px]">
                  Leverage used:
                </span>
                <div
                  className={`${
                    isAvailable ? "bg-[#292C3C]" : "bg-[#2F3144]"
                  } whitespace-nowrap flex text-[8px] items-center py-1 justify-center rounded-xl px-2 ${textDark} font-semibold`}
                >
                  {strategy.leverage}
                </div>
              </div>

              <div className={`flex flex-col`}>
                <span className="whitespace-nowrap text-[8px]">
                  Safety net:
                </span>
                <div
                  className={`${
                    isAvailable ? "bg-[#292C3C]" : "bg-[#2F3144]"
                  } text-[8px] flex justify-center py-1 items-center rounded-xl px-2 h-6 ${textDark} font-semibold whitespace-nowrap`}
                >
                  {strategy.safety_net}
                </div>
              </div>
            </div>
            <div className={`flex justify-between items-center w-full`}>
              <span className="text-[8px]">
                Average monthly compounded return:
              </span>
              <div
                className={`${
                  isAvailable ? "bg-[#292C3C]" : "bg-[#2F3144]"
                } flex items-center rounded-xl px-2 h-6 text-[8px] ${textDark} font-semibold`}
              >
                {strategy.return}
              </div>
            </div>
          </div>

          <div
            className={`${
              isAvailable ? "bg-[#292C3C]" : ""
            } min-w-[73px] rounded-xl mx-1.5 px-2 flex flex-col justify-center max-h-32`}
          >
            <div className="flex items-center gap-1">
              <div className="">
                {strategy.deposit_coin === "usd" ? (
                  <div
                    className={` ${
                      strategy.isAvailable ? "" : "filter grayscale"
                    }`}
                  >
                    <img className="w-[12px]" src={tether} alt="coin_logo" />
                    <img className="w-[12px] ml-2" src={usdc} alt="coin_logo" />
                  </div>
                ) : (
                  <img
                    className={`w-4 ${
                      strategy.isAvailable ? "" : "filter grayscale"
                    }`}
                    src={
                      coins.filter((e) => e.name === strategy.deposit_coin)[0]
                        .value
                    }
                    alt="coin_logo"
                  />
                )}
              </div>
              <span
                className={`text-[#d9d9d9a6] text-[8px] font-bold whitespace-nowrap`}
              >
                Deposit
              </span>
            </div>

            <div className="flex justify-center p-4">
              <img
                src={green_path}
                className={`w-4 ${
                  strategy.isAvailable ? "" : "filter grayscale"
                }`}
                alt=""
              />
            </div>

            <div className="flex items-center justify-end gap-2">
              <span
                className={`text-[#d9d9d9a6] text-[8px] font-bold whitespace-nowrap`}
              >
                Profit in
              </span>
              <div className="flex gap-1">
                {strategy.profit_coin === "usd" ? (
                  <div
                    className={` ${
                      strategy.isAvailable ? "" : "filter grayscale"
                    }`}
                  >
                    <img className="w-[12px]" src={tether} alt="coin_logo" />
                    <img className="w-[12px] ml-2" src={usdc} alt="coin_logo" />
                  </div>
                ) : (
                  <img
                    className={`w-4 ${
                      strategy.isAvailable ? "" : "filter grayscale"
                    }`}
                    src={
                      coins.filter((e) => e.name === strategy.profit_coin)[0]
                        .value
                    }
                    alt="coin_logo"
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between">
          {/* <span className={`whitespace-nowrap text-[10px] ${textDark}`}>
            Exchanges available:
          </span> */}

          <div
            className={`${
              isAvailable ? "bg-[#292C3C]" : "bg-[#2F3144]"
            } py-2 mt-2 px-8 mx-1 rounded-2xl grid grid-flow-col-dense gap-4 items-center justify-center`}
          >
            {wallets.map((src, i) => (
              <img
                key={`${i}`}
                src={src}
                className={`w-6 ${
                  strategy.isAvailable ? "" : "filter grayscale"
                }`}
                alt=""
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
