import React, { useState } from "react";
import Header from "./Header";
import Sidebar from "./Sidebar";
import Main from "./Main";
import MainWrapper from "../MainWrapper";

function Index() {
  const [showSideBar, setShowSideBar] = useState(false);
  return (
    <MainWrapper>
      <Sidebar
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        active={{ id: "1", href: "/dashboard" }}
      />
      <Header setShowSideBar={setShowSideBar} showSideBar={showSideBar} />
      <Main />
    </MainWrapper>
  );
}

export default Index;
