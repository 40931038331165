import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "react-circular-progressbar/dist/styles.css";
import { ToastContainer } from "react-toastify";
import Routing from "./Routing";
import { AppContextProvider } from "./Context";
import { useLocalStorage } from "./hooks";
import Intercom from "@intercom/messenger-js-sdk";
import { useState } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";

function App() {
  const [user] = useLocalStorage("user", {});

  console.log(user);

  Intercom({
    app_id: "xd17lti9",
    // user_id: user?._id, // IMPORTANT: Replace "user.id" with the variable you use to capture the user's ID
    name: user?.name, // IMPORTANT: Replace "user.name" with the variable you use to capture the user's name
    email: user?.email, // IMPORTANT: Replace "user.email" with the variable you use to capture the user's email
    created_at: user?.createdAt, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
    user_hash: user?.intercom,
  });
  return (
    <AppContextProvider>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <Routing />
        <ToastContainer
          theme="dark"
          hideProgressBar
          closeButton={false}
          icon={false}
        />
      </GoogleOAuthProvider>
    </AppContextProvider>
  );
}

export default App;
