import React, { useEffect, useState } from "react";
import Wrapper from "../Dashboard/DashboardWrapper";
import Loader from "../Loader";
import { useLoading, useLocalStorage } from "../../hooks";
import { Bar, Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import green_arrow from "../../assets/icons/Green_arrow.svg";
import green_path from "../../assets/icons/path.svg";
import logo from "../../assets/images/logo.png";
import front_arrow from "../../assets/icons/front-arrow.png";
import { USD_BOTS } from "../Dashboard/BotActivate/CardLarge";
import usdc from "../../assets/icons/usd-coin.svg";
import tether from "../../assets/icons/tether-usdt.svg";
import { FetchHistogramData, FetchStrategy } from "../../API";
import { toast } from "react-toastify";
import { formatDay } from "../../utilities";
import ethereum from "../../assets/icons/ethereum.svg";
import solana from "../../assets/icons/solana.svg";
import bitcoin from "../../assets/icons/bitcoin.svg";

const years = ["2020", "2021", "2022", "2023", "2024"];

const months_of_year = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

Chart.register(CategoryScale);

const coins = [
  { name: "btc", value: bitcoin },
  { name: "eth", value: ethereum },
  { name: "sol", value: solana },
];

const bot_select = (name) => {
  let result = { bg: "bg-orange-light", text: "text-orange-dark" };
  if (name == "ETH") result = { bg: "bg-blue-light", text: "text-blue-dark" };
  if (name == "SOL")
    result = { bg: "bg-purple-light", text: "text-purple-dark" };
  if (name == "USD") result = { bg: "bg-green-light", text: "text-green-dark" };
  return result;
};

function Index({ details: item }) {
  const [data, setData] = useState({});
  const [isLoading, startLoading, stopLoading] = useLoading();
  const [month, setMonth] = useState("");
  const [year, setYear] = useState();
  const [type, setType] = useState("chart");
  const [authToken] = useLocalStorage("authToken", "");
  const [chartData, setChartData] = useState({
    labels: [].map((data) => data),
    datasets: [
      {
        label: "2023",
        data: [].map((data) => data?.usd_profit),
        backgroundColor: ["#50AF95"],
        borderColor: "#50AF95",
        borderWidth: 2,
      },
      {
        label: "2024",
        data: [].map((data) => data?.usd_profit),
        backgroundColor: ["#50AF95"],
        borderColor: "#50AF95",
        borderWidth: 2,
      },
    ],
  });

  const fetchData = async () => {
    startLoading();
    setData({});
    const res = await FetchStrategy({
      strategy: item.strategy.name,
      year,
      month,
    });
    console.log(res);
    if (res?.success) {
      setData(res.result);
    } else {
      toast.error(res?.message || "Unable to fetch data", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  const fetchHistogram = async () => {
    startLoading();
    setData({});
    const res = await FetchHistogramData(item.strategy.name, authToken);
    console.log(res);
    if (res?.success) {
      setChartData({
        labels: res.year1.map((data) => data?.month),
        datasets: [
          {
            label: "2023",
            data: res.year1.map((data) => data?.performance),
            backgroundColor: ["#50AF95"],
            borderColor: "#50AF95",
            borderWidth: 2,
          },
          {
            label: "2024",
            data: res.year2.map((data) => data?.performance),
            backgroundColor: ["#3D85C6"],
            borderColor: "#3D85C6",
            borderWidth: 2,
          },
        ],
      });
    } else {
      toast.error(res?.message || "Unable to fetch data", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  useEffect(() => {
    if (item && year && month) fetchData();
  }, [item, year, month]);

  return (
    <Wrapper className={"px-4 py-4 rounded-md relative bg-[#292C3C]"}>
      <Loader isLoading={isLoading} />
      <div
        onClick={() => window.open("/dashboard/data-room", "_self")}
        className="flex items-center gap-4 py-8 mt-16 cursor-pointer"
      >
        <img src={front_arrow} className="w-8 rotate-[180deg]" />
        <span className="font-semibold">Data room</span>
      </div>

      <div className="flex gap-8 overflow-x-scroll">
        <div className="flex flex-col gap-2 sm:w-[90%] ">
          <div className="bg-[#151924] py-8 px-6 rounded-xl min-h-[55vh]">
            {data.strategy_id ? (
              <img className="w-full h-full" src={data.trading_view_link} />
            ) : (
              <div className="flex items-center justify-center h-full">
                {type === "histogram" ? (
                  <Bar
                    data={chartData}
                    options={{
                      plugins: {
                        title: {
                          display: true,
                          text: "",
                        },
                      },
                    }}
                  />
                ) : (
                  <>
                    {item.strategy.name === "CB012" ? (
                      <span className="px-20 leading-relaxed text-md">
                        {`We have no back testing data available for CB021 as for this
                    strategy, you are the one who chooses which alt coin's price
                    action's volatility you would like to use to generate USD as
                    profit while holding USD as base currency. This strategy
                    minimum deposit requires at least $50,000 USD and you also
                    need to provide us from 5 to 10 alt coins for us to use as
                    index to trade. You can find more information about this
                    strategy on our knowledge base, frequently asked questions and
                    at the bot activation screen.`}{" "}
                      </span>
                    ) : (
                      <>
                        {month && !year && "Select year"}
                        {!month && year && "Select month"}
                        {!month && !year && "Select year and month"}
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </div>

          <div className="flex justify-between">
            {data.strategy_id && (
              <a
                href={`${data.trading_view_link}`}
                target="_blank"
                rel="noreferrer"
              >{`<open chart in new tab>`}</a>
            )}

            <div className="flex gap-8">
              <a
                href="https://help.cryptobots.io/"
                target="_blank"
                rel="noreferrer"
              >{`<Knowledge base>`}</a>
              {data.strategy_id && (
                <a
                  href={`/dashboard/bot-activate?profit_coin=${item.strategy.profit_coin}&deposit_coin=${item.strategy.deposit_coin}`}
                  target="_blank"
                  rel="noreferrer"
                >{`<Activate bot>`}</a>
              )}
            </div>
          </div>

          <div className="flex flex-col gap-4 sm:flex-row sm:justify-between sm:items-center sm:gap-8">
            <div className="flex bg-[#151924] sm:flex-wrap rounded-xl py-4 px-4 gap-4 cursor-pointer w-[50%]">
              {years.map((e) => (
                <div
                  key={e}
                  onClick={() => {
                    setYear(e);
                    setType("chart");
                  }}
                  className={`${
                    e === year ? " bg-[#A8A8A8]" : "bg-[#2F3144]"
                  } px-6 py-1 rounded-lg text-sm  hover:scale-110 transition-transform duration-300  ${
                    bot_select(item.denomination).text
                  }`}
                >
                  {e}
                </div>
              ))}
              <div
                onClick={() => {
                  if (type === "histogram") {
                    setType("chart");
                  } else {
                    setYear("");
                    setMonth("");
                    fetchHistogram();
                    setType("histogram");
                  }
                }}
                className={`${
                  type === "histogram" ? "bg-[#A8A8A8]" : "bg-[#2F3144]"
                } px-6 py-1 rounded-lg text-sm  hover:scale-110 transition-transform duration-300  ${
                  bot_select(item.denomination).text
                }`}
              >
                Histogram
              </div>
            </div>

            <div className="flex bg-[#151924] sm:flex-wrap rounded-xl py-4 px-4 gap-4 cursor-pointer">
              {months_of_year.map((e) => (
                <div
                  key={e}
                  onClick={() => setMonth(e)}
                  className={`${
                    e === month ? " bg-[#A8A8A8]" : "bg-[#2F3144]"
                  } px-6 py-1 rounded-lg text-sm  hover:scale-110 transition-transform duration-300  ${
                    bot_select(item.denomination).text
                  }`}
                >
                  {e}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-6 ">
          <div className="py-8 px-6 bg-[#151924] rounded-xl flex flex-col items-center gap-8 font-semibold text-sm">
            <span className={`text-3xl ${bot_select(item.denomination).text}`}>
              {item.strategy.name}
            </span>

            <div className="flex flex-col items-center justify-center gap-2">
              <div className="bg-[#2F3144] px-6 py-1 rounded-md">
                {data.performance} {data.performance ? "%" : ""}
              </div>
              <span className="text-[#B3CFEC]">Profit</span>
            </div>

            <div className="flex flex-col items-center justify-center gap-2">
              <div className="bg-[#2F3144] px-6 py-1 rounded-md">
                {data.deals_completed}
              </div>
              <span className="text-[#B3CFEC] text-center">
                Completed trades
              </span>
            </div>

            <div className="flex flex-col items-center justify-center gap-2">
              <div
                className={`bg-secondary flex items-center rounded-xl px-6 h-6 text-sm ${
                  bot_select(item.bot).text
                }font-semibold gap-1 uppercase`}
              >
                {data.strategy_direction}
                <img src={green_arrow} alt="green-arrow" />
              </div>
              <span className="text-[#B3CFEC] text-center">Strategy</span>
            </div>

            <div className="flex flex-col items-center justify-center gap-2">
              <span className="text-[#B3CFEC] text-center">Days in deal</span>
              <div className="flex gap-4">
                <div className="flex flex-col items-center">
                  <div className="bg-[#2F3144] px-6 py-1 rounded-md">
                    {data.average_days_in_deal}
                  </div>
                  <span className="text-[#B3CFEC] text-center">Available</span>
                </div>
                <div className="flex flex-col items-center">
                  <div className="bg-[#2F3144] px-6 py-1 rounded-md">
                    {data.maximum_days_in_deal}
                  </div>
                  <span className="text-[#B3CFEC] text-center">Maximum</span>
                </div>
              </div>
            </div>
          </div>

          <div className="flex-col hidden gap-4 px-4 py-2 sm:flex">
            <div className="flex items-center gap-4">
              <div className="flex gap-1">
                {item.strategy.deposit_coin == "usd" ? (
                  <div>
                    <img className="w-6" src={tether} alt="coin_logo" />
                    <img className="w-6 ml-4" src={usdc} alt="coin_logo" />
                  </div>
                ) : (
                  <img
                    className={`w-8`}
                    src={
                      coins.filter(
                        (e) => e.name === item.strategy.deposit_coin
                      )[0].value
                    }
                    alt="coin_logo"
                  />
                )}
              </div>
              <span className={`${bot_select(item.denomination).text} text-md`}>
                Deposit
              </span>
            </div>

            <div className="flex justify-center p-2">
              <img src={green_path} className="w-14" alt="" />
            </div>

            <div className="flex items-center justify-end gap-4">
              <span className={`${bot_select(item.denomination).text} text-md`}>
                Profit in
              </span>
              <div className="flex gap-1">
                {item.strategy.profit_coin == "usd" ? (
                  <div>
                    <img className="w-6" src={tether} alt="coin_logo" />
                    <img className="w-6 ml-6" src={usdc} alt="coin_logo" />
                  </div>
                ) : (
                  <img
                    className={`w-8`}
                    src={
                      coins.filter(
                        (e) => e.name === item.strategy.profit_coin
                      )[0].value
                    }
                    alt="coin_logo"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="items-center justify-center hidden mt- sm:flex">
            <img className="w-12 rounded-full" src={logo} alt="logo" />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Index;
