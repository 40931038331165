import React from "react";
import chart from "../../../assets/images/chart-blue.svg";
import chart_2 from "../../../assets/images/chart-blue.png";
import NavHeader from "../../NavHeader";
import threecommas from "../../../assets/images/3commas-1 1.png";

function Hero({ locale, setLocale }) {
  return (
    <div className="bg-chart-blue flex flex-col py-14 items-center bg-cover h-screen w-full bg-no-repeat bg-[#151923] relative background-image">
      <div className="fixed top-0 z-50 w-full">
        <NavHeader locale={locale} setLocale={setLocale} />
      </div>
      <div className="flex flex-col px-4 mt-20 opacity-60 sm:px-0">
        <span className="font-bold sm:text-3xl text-md">
          {locale === "es"
            ? "Bots comerciales 100% automatizados para activos criptodigitales"
            : "100% automated trading bots for crypto digital assets."}
        </span>
        <span className="sm:text-xl text-md opacity-65">
          {locale === "es"
            ? "¡Un sistema donde usted mantiene el control de sus fondos en todo momento!"
            : "a system where you remain in control of your funds at all times!"}
        </span>
      </div>
      <div
        onClick={() => window.open("/dashboard/select-bot", "_self")}
        className="absolute px-6 py-2 rounded-full cursor-pointer bg-green-dark sm:text-xl bottom-24"
      >
        {locale === "es" ? "panel" : "Dashboard"}
      </div>
      {/* <div className="absolute bottom-3 left-3">
        <p className="text-sm">Powered by:</p>
        <img src={threecommas} alt="" />
      </div> */}
    </div>
  );
}

export default Hero;
