import React, { useEffect, useState } from "react";
import Wrapper from "../Dashboard/DashboardWrapper";
import Loader from "../Loader";
import { useLoading, useLocalStorage } from "../../hooks";
import MainWrapper from "../MainWrapper";
import Header from "../Dashboard/Header";
import Sidebar from "../Dashboard/Sidebar";
import btc_pay from "../../assets/images/btc_pay.png";
import { toast } from "react-toastify";
import { FetchInvoices } from "../../API";
import { confirmSignIn } from "../../utilities";

function Main() {
  const [showSideBar, setShowSideBar] = useState(false);
  const [isLoading, startLoading, stopLoading] = useLoading();
  const [authToken, setAuthToken] = useLocalStorage("authToken", "");
  const [invoices, setInvoices] = useState([]);

  const fetchInvoices = async () => {
    startLoading();
    const res = await FetchInvoices(authToken);
    if (res?.success) {
      setInvoices(res.invoices);
    } else {
      confirmSignIn(res.message, window, setAuthToken);
      toast.error(res?.message || "Unable to fetch invoices", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  useEffect(() => {
    if (!authToken) {
      toast.error("Please sign in to continue", { position: "top-center" });
      setTimeout(() => {
        window.open("/dashboard", "_self");
      }, 3000);
    } else {
      fetchInvoices();
    }
  }, []);

  return (
    <MainWrapper>
      <Sidebar
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        active={{ id: "3", href: "/dashboard/profit-share" }}
      />
      <Header showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <Wrapper className={"px-4 py-4 rounded-xl relative bg-[#292C3C]"}>
        <Loader isLoading={isLoading} />

        <div className="bg-[#151924] py-8 sm:px-6 px-4 rounded-xl mt-16">
          <div className="sm:text-md mb-4">
            Around every 30 days, we will take a snap shot of your accumulated
            profits and request you to send us 33% in the currency you profited
            in.
          </div>
          <div className="overflow-x-scroll">
            <table className=" bg-primary rounded-lg w-full">
              <thead>
                <tr className="border-b-[0.5px] border-[#fff] whitespace-nowrap">
                  <th className="text-left px-8 py-4">Strategy</th>
                  <th className="text-left px-8 py-4">Period</th>
                  <th className="text-left px-8 py-4">Profit share</th>
                </tr>
              </thead>

              <tbody>
                {invoices.length > 0 ? (
                  invoices.map((item, index) => (
                    <tr className="whitespace-nowrap" key={item}>
                      <td className="px-8 py-4">{item.bot.strategy}</td>
                      <td className="px-8 py-4">
                        {item.from}-{item.to}
                      </td>
                      <td className="px-8 py-4">
                        {item.amount} {item.currency}
                      </td>
                      <td className="px-4 py-4 ">
                        <div
                          onClick={() =>
                            item.status == "UNPAID"
                              ? window.open(
                                  item.checkout.checkout_url,
                                  "_blank"
                                )
                              : ""
                          }
                          className={`py-2 px-1 flex justify-center ${
                            item.status == "UNPAID"
                              ? "border cursor-pointer"
                              : ""
                          }`}
                        >
                          {item.status == "UNPAID" ? "Pay now" : "Paid"}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan={3}
                      className="text-center sm:text-lg mt-20 font-semibold opacity-50 font-public-sans py-12"
                    >
                      Nothing is here yet!
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Wrapper>
    </MainWrapper>
  );
}

export default Main;
