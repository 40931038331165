import React from "react";

import { ColorCard } from "../../Dashboard/BotActivate/CardLarge";
import {
  CB001,
  CB002,
  CB003,
  CB004,
  CB005,
  CB006,
  CB007,
  CB008,
  CB009,
  CB010,
  CB011
} from "../../DataRoom/Main";

function Results({
  setShowResult,
  depositCoin: deposit_coin,
  profitCoin: profit_coin,
  exchange,
  locale,
}) {
  return (
    <div className="px-4 py-10 sm:px-10">
      <div className="px-2 py-8 bg-secondary sm:px-12 rounded-2xl">
        <div
          className="flex justify-center py-4 sm:text-lg "
          onClick={() => setShowResult(false)}
        >
          <div
            onClick={() => setShowResult(false)}
            className="px-4 py-2 border border-white rounded-full cursor-pointer"
          >
            {locale === "es" ? "selección clara" : "clear selection"}
          </div>
        </div>
        <div className="py-8 sm:px-12">
          <div className="flex flex-wrap gap-10">
            {profit_coin === "btc" && deposit_coin === "btc" && (
              <>
                <ColorCard {...CB001} />
                <ColorCard
                  {...CB002}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />
              </>
            )}

            {deposit_coin === "btc" &&
              (profit_coin === "usdc" || profit_coin === "usdt") && (
                <>
                  <ColorCard
                    {...CB003}
                    onClick={() =>
                      window.open(
                        `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                      )
                    }
                  />
                </>
              )}

            {profit_coin === "eth" && deposit_coin === "eth" && (
              <>
                <ColorCard {...CB004} />
                <ColorCard
                  {...CB005}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />
              </>
            )}

            {deposit_coin === "eth" &&
              (profit_coin === "usdc" || profit_coin === "usdt") && (
                <>
                  <ColorCard
                    {...CB006}
                    onClick={() =>
                      window.open(
                        `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                      )
                    }
                  />
                </>
              )}

            {profit_coin === "sol" && deposit_coin === "sol" && (
              <>
                <ColorCard {...CB007} />
                <ColorCard
                  {...CB008}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />
              </>
            )}

            {deposit_coin === "sol" &&
              (profit_coin === "usdc" || profit_coin === "usdt") && (
                <>
                  <ColorCard
                    {...CB009}
                    onClick={() =>
                      window.open(
                        `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                      )
                    }
                  />
                </>
              )}

            {(profit_coin === "usdt" && deposit_coin === "usdt") ||
            (profit_coin === "usdc" && deposit_coin === "usdc") ||
            (profit_coin === "usdc" && deposit_coin === "usdt") ||
            (profit_coin === "usdt" && deposit_coin === "usdc") ? (
              <>
                <ColorCard
                  {...CB010}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />
                <ColorCard {...CB011} />
                {/* <ColorCard
                  {...CB012}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                /> */}
              </>
            ) : (
              <></>
            )}

            {["binance", "bybit", "okx"].includes(exchange) && (
              <>
                <ColorCard
                  {...CB001}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />
                <ColorCard
                  {...CB002}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />
                <ColorCard
                  {...CB003}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />
                <ColorCard
                  {...CB004}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />
                <ColorCard
                  {...CB005}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />
                <ColorCard
                  {...CB006}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />
                <ColorCard
                  {...CB007}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />
                <ColorCard
                  {...CB008}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />
                <ColorCard
                  {...CB009}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />
                <ColorCard
                  {...CB010}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />
                <ColorCard
                  {...CB011}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />
                {/* <ColorCard
                  {...CB012}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                /> */}
              </>
            )}

            {["gate_io"].includes(exchange) && (
              <>
                <ColorCard
                  {...CB001}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />
                <ColorCard
                  {...CB002}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />
                <ColorCard
                  {...CB003}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />
                <ColorCard
                  {...CB004}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />

                <ColorCard
                  {...CB006}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />
                <ColorCard
                  {...CB007}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />
                <ColorCard
                  {...CB009}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />
                <ColorCard
                  {...CB010}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />
                <ColorCard
                  {...CB011}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />
                {/* <ColorCard
                  {...CB012}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                /> */}
              </>
            )}

            {["binance_us", "coinbase"].includes(exchange) && (
              <>
                <ColorCard
                  {...CB001}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />

                <ColorCard
                  {...CB004}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />

                <ColorCard
                  {...CB007}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />

                <ColorCard
                  {...CB010}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />

                {/* <ColorCard
                  {...CB012}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                /> */}
              </>
            )}

            {["kraken", "gemini"].includes(exchange) && (
              <>
                <ColorCard
                  {...CB001}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />

                <ColorCard
                  {...CB004}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />

                <ColorCard
                  {...CB007}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />

                <ColorCard
                  {...CB010}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />
              </>
            )}

            {["bitget"].includes(exchange) && (
              <>
                <ColorCard
                  {...CB011}
                  onClick={() =>
                    window.open(
                      `/dashboard/bot-activate?profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`
                    )
                  }
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Results;
