import React from "react";
import logo from "../../../assets/images/logo.png";
import bind from "../../../assets/icons/010-card 1.svg";
import ai from "../../../assets/icons/033-computer 1.png";
import gen from "../../../assets/icons/passive-income 1.svg";
import equality from "../../../assets/icons/Vector.svg";
import twenty4_7 from "../../../assets/icons/022-clock 1.svg";
import calculator from "../../../assets/icons/020-benefit 1.svg";
import refer from "../../../assets/icons/017-analytics 1.svg";
import divide from "../../../assets/icons/025-no 1.svg";
import rocket from "../../../assets/icons/026-thunderbolt 1.svg";
import network from "../../../assets/icons/029-customer-service 1.svg";
import wheel from "../../../assets/icons/031-quality 1.svg";
import telescope from "../../../assets/icons/014-technology 1.svg";
import key from "../../../assets/icons/007-connection-2 1.svg";
import computer_network from "../../../assets/icons/035-laptop-screen 1.svg";

function Guide({ locale }) {
  return (
    <div className="relative p-clamp-padding-guide ">
      <div className="flex items-center justify-center gap-4 opacity-100">
        <img className="w-8" src={logo} alt="logo" />
        <span className="text-lg font-semibold sm:text-6xl opacity-60">cryptobots.io</span>
      </div>

      <div className="grid gap-20 mx-auto mt-24 sm:px-20 lg:grid-cols-3 xl:gap-40 w-clamp-width-guide">
        <div className="flex flex-col gap-20">
          <div className="flex items-center gap-4">
            <img src={bind} alt="bind" />
            <span className="text-lg opacity-60">
              {locale === "es"
                ? "0$ para comenzar no se requiere tarjeta de crédito."
                : "0$ to start no credit card required."}
            </span>
          </div>
          <div className="flex items-center gap-4">
            <img src={equality} alt="equality" />
            <span className="text-lg opacity-60">
              {locale === "es"
                ? "Tus fondos permanecen en tu billetera de intercambio bajo tu control en todo momento."
                : "Your funds stay in your exchange wallet under your control at all times."}
            </span>
          </div>
          <div className="flex items-center gap-4">
            <img src={twenty4_7} alt="24/7" />
            <span className="text-lg opacity-60">
              {locale === "es"
                ? "Benefíciese de la volatilidad del mercado abierto las 24 horas, los 7 días de la semana."
                : "Benefit from 24/7 open market volatility."}
            </span>
          </div>
        </div>

        <div className="flex flex-col gap-20">
          <div className="flex items-center gap-4">
            <img src={calculator} alt="calculator" />
            <span className="text-lg opacity-60">
              {locale === "es"
                ? "¡No nos paga a menos que primero obtenga ganancias!"
                : "You don't pay us unless you profit first!"}
            </span>
          </div>
          <div className="flex items-center gap-4">
            <img src={refer} alt="refer" />
            <span className="text-lg opacity-60">
              {locale === "es"
                ? "Gane y retire el 25 % de todas las tarifas de rendimiento de sus referencias."
                : "Earn and withdraw 25% of all your referrals performance fees."}
            </span>
          </div>
          
          <div className="flex items-center gap-4">
            <img src={divide} alt="divide" />
            <span className="text-lg opacity-60">
              {locale === "es"
                ? "Sin Contrato Sin comisión de gestión Sin Lock Up Sin plazo mínimo Cancelar en cualquier momento."
                : "No Contract No management fee No Lock Up No minimum term Cancel anytime"}
            </span>
          </div>
        </div>
        <div className="flex flex-col gap-16">
          <div className="flex items-center gap-4">
            <img src={rocket} alt="rocket" />
            <span className="text-lg opacity-60">
              {locale === "es"
                ? "Benefíciese de las maravillas de los rendimientos compuestos."
                : "Benefit from the wonders of compounding returns."}
            </span>
          </div>

          <div className="flex items-center gap-4">
            <img src={network} alt="network" />
            <span className="text-lg opacity-60">
              {locale === "es"
                ? "Nuestro equipo de soporte está disponible de lunes a viernes."
                : "Our Support team is on hand M-F"}
            </span>
          </div>
          <div className="flex items-center gap-4">
            <img src={gen} alt="refer" />
            <span className="text-lg opacity-60">
              {locale === "es"
                ? "Genera flujo de efectivo pasivo de manera independiente."
                : "Generate passive cash-flow independently."}
            </span>
          </div>
          <div className="flex items-center gap-4">
            <img src={wheel} alt="wheel" />
            <span className="text-lg opacity-60">
              {locale === "es"
                ? "Rápido, fácil, seguro!"
                : "Fast, easy , safe!"}
            </span>
          </div>
        </div>
      </div>

      <div className="relative flex justify-between gap-20 mt-10 mb-10 md:mb-0 p-clamp-padding-guide md:mt-3">
        <div className="relative max-sm:hidden sm:w-64 ">
          <img className="absolute z-10 w-64 top-20 " src={computer_network} alt="logo" />
          <img className="absolute self-start w-32 left-52" src={telescope} alt="logo" />
        </div>
        <div
          onClick={() => window.open("/dashboard/select-bot", "_self")}
          className="flex items-center self-end h-12 px-16 mx-auto text-2xl rounded-full cursor-pointer bg-green-light"
        >
          {locale === "es" ? "panel" : "Dashboard"}
        </div>
        <div className="relative w-52 h-44 max-sm:hidden">
          <img className="absolute self-start w-40 left-12" src={ai} alt="logo" />
          <img className="absolute self-end w-64 top-28 right-24" src={key} alt="logo" />
        </div>
      </div>

      <div className="absolute flex items-center gap-4 opacity-100 bottom-4 left-8">
        <img className="w-6" src={logo} alt="logo" />
        <span className="text-lg font-semibold">cryptobots.io</span>
      </div>
    </div>
  );
}

export default Guide;
