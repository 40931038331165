import React, { useEffect, useRef, useState } from "react";
import { useLoading } from "../../hooks";
import { UpdateInvoice, VerifyEmail } from "../../API";
import { toast } from "react-toastify";
import { useLocation } from "react-router";
import Loader from "../Loader";
import MainWrapper from "../MainWrapper";

function Index() {
  const [isLoading, startLoading, stopLoading] = useLoading();
  const isComponentMounted = useRef(true);

  // Access location object using useLocation hook
  const location = useLocation();

  // Parse the search string to extract query parameters
  const queryParams = new URLSearchParams(location.search);

  // Get individual query parameters by key
  const invoice_id = queryParams.get("invoice_id");
  console.log(invoice_id);

  useEffect(() => {
    // Set isComponentMounted to false when the component unmounts
    return () => {
      isComponentMounted.current = false;
    };
  }, []);

  const handlePaymentComplete = async () => {
    startLoading();
    const req = await UpdateInvoice({ _id: invoice_id });

    if (req?.success) {
      toast.success(req.message, {
        position: "top-center",
      });

      setTimeout(() => {
        window.open("/dashboard", "_self");
      }, 3000);
    } else {
      toast.error(req?.message || "Error occured", {
        position: "top-center",
      });
      setTimeout(() => {
        window.open("/dashboard", "_self");
      }, 3000);
    }
    stopLoading();
  };

  useEffect(() => {
    if (invoice_id && isComponentMounted.current) handlePaymentComplete();
  }, [invoice_id]);

  return (
    <MainWrapper className={"relative bg-[#292C3C]"}>
      <Loader isLoading={isLoading} />
    </MainWrapper>
  );
}

export default Index;
