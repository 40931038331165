import React, { useState } from "react";
import okx from "../../../assets/icons/OKX.svg";
import kraken from "../../../assets/icons/Kraken.svg";
import gate_io from "../../../assets/icons/full-gate.svg";
import coinbase from "../../../assets/icons/coinbase.svg";
import gemini from "../../../assets/icons/gemini.svg";
import kucoin from "../../../assets/icons/kucoin.svg";
import binance from "../../../assets/icons/binance.svg";
import binance_us from "../../../assets/icons/Binance-us.svg";
import bitget from "../../../assets/icons/Bitget.svg";
import bybit from "../../../assets/icons/bybit.svg";

function SelectExchange({ setexchangeNetwork, exchangeNetwork, title }) {
  return (
    <div className="">
      <div className=" flex flex-col sm:gap-10 gap-8">
        <div className="flex sm:gap-20 gap-12 self-center">
          <div
            onClick={() => setexchangeNetwork("okx")}
            className={`sm:w-20 sm:h-20 w-16 h-16 ${
              exchangeNetwork == "okx"
                ? "border-2 rounded-3xl"
                : "bg-transparent"
            } hover:scale-125 transition-transform duration-300 cursor-pointer flex justify-center`}
          >
            <img alt="okx" src={okx} className="sm:w-16 w-14" />
          </div>
          <div
            onClick={() => setexchangeNetwork("binance")}
            className={`sm:w-20 sm:h-20 w-16 h-16 ${
              exchangeNetwork == "binance"
                ? "border-2 rounded-3xl"
                : "bg-transparent"
            } hover:scale-125 transition-transform duration-300 cursor-pointer flex justify-center`}
          >
            <img alt="binance" src={binance} className={`sm:w-16 w-14`} />
          </div>

          <div
            onClick={() => setexchangeNetwork("bybit")}
            className={`sm:w-20 sm:h-20 w-16 h-16 ${
              exchangeNetwork == "bybit"
                ? "border-2 rounded-3xl"
                : "bg-transparent"
            } hover:scale-125 transition-transform duration-300 cursor-pointer flex justify-center`}
          >
            <img alt="bybit" src={bybit} className={`sm:w-16 w-14`} />
          </div>
        </div>

        <div className="flex sm:gap-20 gap-12  self-center">
          <div
            onClick={() => setexchangeNetwork("coinbase")}
            className={`sm:w-20 sm:h-20 w-16 h-16 ${
              exchangeNetwork == "coinbase"
                ? "border-2 rounded-3xl"
                : "bg-transparent"
            } hover:scale-125 transition-transform duration-300 cursor-pointer flex justify-center`}
          >
            <img alt="coinbase" src={coinbase} className={`sm:w-16 w-14`} />
          </div>
          <div
            onClick={() => setexchangeNetwork("kraken")}
            className={`sm:w-20 sm:h-20 w-16 h-16 ${
              exchangeNetwork == "kraken"
                ? "border-2 rounded-3xl"
                : "bg-transparent"
            } hover:scale-125 transition-transform duration-300 cursor-pointer flex justify-center`}
          >
            <img alt="kraken" src={kraken} className={`sm:w-16 w-14`} />
          </div>
          <div
            onClick={() => setexchangeNetwork("bitget")}
            className={`sm:w-20 sm:h-20 w-16 h-16 ${
              exchangeNetwork == "bitget"
                ? "border-2 rounded-3xl"
                : "bg-transparent"
            } hover:scale-125 transition-transform duration-300 cursor-pointer flex justify-center`}
          >
            <img alt="bitget" src={bitget} className={`w-sm:w-16 w-14`} />
          </div>
        </div>
        <div className="flex sm:gap-20 gap-12  self-center">
          <div
            onClick={() => setexchangeNetwork("kucoin")}
            className={`sm:w-20 sm:h-20 w-16 h-16 ${
              exchangeNetwork == "kucoin"
                ? "border-2 rounded-3xl"
                : "bg-transparent"
            } hover:scale-125 transition-transform duration-300 cursor-pointer flex justify-center`}
          >
            <img alt="kucoin" src={kucoin} className={`sm:w-16 w-14`} />
          </div>
          <div
            onClick={() => setexchangeNetwork("gate_io")}
            className={`sm:w-20 sm:h-20 w-16 h-16 ${
              exchangeNetwork == "gate_io"
                ? "border-2 rounded-3xl"
                : "bg-transparent"
            } hover:scale-125 transition-transform duration-300 cursor-pointer flex justify-center`}
          >
            <img alt="gate_io" src={gate_io} className={`sm:w-16 w-14`} />
          </div>
          <div
            onClick={() => setexchangeNetwork("binance_us")}
            className={`sm:w-20 sm:h-20 w-16 h-16 ${
              exchangeNetwork == "binance_us"
                ? "border-2 rounded-3xl"
                : "bg-transparent"
            } hover:scale-125 transition-transform duration-300 cursor-pointer flex justify-center`}
          >
            <img alt="binance_us" src={binance_us} className={`sm:w-16 w-14`} />
          </div>
        </div>
        <div
          onClick={() => setexchangeNetwork("gemini")}
          className={`sm:w-20 sm:h-20 w-16 h-16 ${
            exchangeNetwork == "gemini"
              ? "border-2 rounded-3xl"
              : "bg-transparent"
          } hover:scale-125 transition-transform duration-300 cursor-pointer flex justify-center self-center`}
        >
          <img alt="gemini" src={gemini} className={`sm:w-16 w-14`} />
        </div>
      </div>
    </div>
  );
}

export default SelectExchange;
