import React, { useEffect } from "react";
import { Route, Routes, BrowserRouter as Router } from "react-router-dom";
import { toast } from "react-toastify";
import Home from "./components/LandingPages/Home";
import Dashboard from "./components/Dashboard";
import SelectBot from "./components/Dashboard/SelectBot";
import BotActivate from "./components/Dashboard/BotActivate";
import BotActivateDetails from "./components/Dashboard/BotActivate/Details";
import Active from "./components/ActiveBot";
import Signup from "./components/Dashboard/Signup";
import VerifyEmail from "./components/Dashboard/VerifyEmail";
import ForgotPassword from "./components/Dashboard/ForgotPassword";
import ChangePassword from "./components/Dashboard/ChangePassword";
import Stats from "./components/ActiveBot/Stats";
import Profit from "./components/Profit/Main";
import Affliate from "./components/Affliate/Main";
import Withdraw from "./components/Affliate/Withdraw";
import DataRoom from "./components/DataRoom/Index";
import Help from "./components/Help/Main";
import Profile from "./components/Profile";
import PaymentSucess from "./components/Dashboard/PaymentSucess";
import { useLocalStorage } from "./hooks";
import Chart from "./components/ActiveBot/Chart";
import Pair from "./components/ActiveBot/Pair";
import Notifications from "./components/Notifications";

function Routing({}) {
  const [authToken] = useLocalStorage("authToken", "");
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route exact path="/verifyEmail" element={<VerifyEmail />}></Route>
        <Route
          exact
          path="/dashboard"
          element={authToken ? <SelectBot /> : <Dashboard />}
        ></Route>
        <Route
          exact
          path="/dashboard/forgotPassword"
          element={<ForgotPassword />}
        ></Route>
        <Route
          exact
          path="dashboard/changePassword"
          element={<ChangePassword />}
        ></Route>
        <Route
          exact
          path="/dashboard/select-bot"
          element={<SelectBot />}
        ></Route>
        <Route exact path="/dashboard/signup" element={<Signup />}></Route>
        <Route exact path="/dashboard/profile" element={<Profile />}></Route>
        <Route
          exact
          path="/dashboard/bot-activate"
          element={<BotActivate />}
        ></Route>
        <Route
          exact
          path="/dashboard/bot-activate-details"
          element={<BotActivateDetails />}
        ></Route>
        <Route exact path="/dashboard/bot-active" element={<Active />}></Route>
        <Route exact path="/dashboard/bot-stats" element={<Stats />}></Route>
        <Route
          exact
          path="/dashboard/bot-stats/pair"
          element={<Pair />}
        ></Route>
        <Route
          exact
          path="/dashboard/bot-stats/pair/chart"
          element={<Chart />}
        ></Route>
        <Route
          exact
          path="/dashboard/profit-share"
          element={<Profit />}
        ></Route>
        <Route exact path="/dashboard/affliate" element={<Affliate />}></Route>
        <Route exact path="/dashboard/withdraw" element={<Withdraw />}></Route>
        <Route exact path="/dashboard/data-room" element={<DataRoom />}></Route>
        <Route exact path="/dashboard/help" element={<Help />}></Route>
        <Route
          exact
          path="/dashboard/notifications"
          element={<Notifications />}
        ></Route>
        <Route
          exact
          path="/dashboard/payment-success"
          element={<PaymentSucess />}
        ></Route>
      </Routes>
    </Router>
  );
}

export default Routing;
