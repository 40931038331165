import React from "react";
import logo from "../../assets/images/logo.png";
import { Dropdown } from "../../hooks/upload";
import { useLocalStorage } from "../../hooks";

function NavHeader({ locale, setLocale }) {
  //const [locale, setLocale] = useLocalStorage("locale", "en");
  return (
    <div className="sm:px-24 py-2 fixed bg-[#171825] w-full">
      <div className="bg-transparent bg-opacity-10 rounded-full justify-between flex items-center px-6 py-2">
        <div className="flex items-center gap-4 opacity-100">
          <img className="sm:w-8 w-4" src={logo} alt="logo" />
          <span className="font-bold sm:text-xl text-md">cryptobots.io</span>
        </div>

        <div className="flex flex-col items-center justify-center gap-2">
          <div className="uppercase">
            <Dropdown
              style="bg-green-dark rounded-full uppercase"
              selected={locale}
              options={[
                { label: "en - English", value: "en" },
                { label: "es - Español", value: "es" },
              ]}
              onChange={(e) => {
                setLocale(e);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavHeader;
