import React, { useEffect, useState } from "react";
import Wrapper from "../Dashboard/DashboardWrapper";
import MainWrapper from "../MainWrapper";
import Sidebar from "../Dashboard/Sidebar";
import Header from "../Dashboard/Header";
import { toast } from "react-toastify";
import { useLoading, useLocalStorage } from "../../hooks";
import { FetchBotDeals, FetchBotProfits, FetchBotStats } from "../../API";
import { useLocation } from "react-router-dom";
import {
  calculateTimeDuration,
  confirmSignIn,
  formatDate,
  formatDay,
} from "../../utilities";
import direction from "../../assets/icons/direction.png";
import front_arrow from "../../assets/icons/front-arrow.png";
import bitcoin from "../../assets/icons/bitcoin.svg";
import ethereum from "../../assets/icons/ethereum.svg";
import solana from "../../assets/icons/solana.svg";
import usdc from "../../assets/icons/usd-coin.svg";
import tether from "../../assets/icons/tether-usdt.svg";

const coins = [
  { name: "BTC", value: bitcoin },
  { name: "ETH", value: ethereum },
  { name: "SOL", value: solana },
  { name: "USDC", value: usdc },
  { name: "USDT", value: tether },
];

function Pair() {
  const [showSideBar, setShowSideBar] = useState(false);
  const [isLoading, startLoading, stopLoading] = useLoading();
  const [authToken, setAuthToken] = useLocalStorage("authToken", "");
  const [deals, setDeals] = useState({});

  const location = useLocation();

  // Parse the search string to extract query parameters
  const queryParams = new URLSearchParams(location.search);

  // Get individual query parameters by key
  const strategy = queryParams.get("strategy");
  const bot_id = queryParams.get("bot_id");
  const exchange = queryParams.get("exchange");
  const profit_coin = queryParams.get("profit_coin");
  const deposit_coin = queryParams.get("deposit_coin");

  const fetchBotDeals = async () => {
    startLoading();
    const res = await FetchBotDeals(authToken, bot_id);
    console.log(res);
    if (res?.success) {
      setDeals(res.deals);
    } else {
      confirmSignIn(res.message, window, setAuthToken);
      toast.error(res?.message || "Unable to fetch data", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  useEffect(() => {
    fetchBotDeals();
  }, []);

  return (
    <MainWrapper>
      <Sidebar
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        active={{ id: "2", href: "/dashboard/bot-active" }}
      />
      <Header showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <Wrapper
        className={
          "px-4 py-4 rounded-md relative bg-[#292C3C] overflow-x-scroll"
        }
      >
        <div
          onClick={() =>
            window.open(
              `/dashboard/bot-stats/?strategy=${strategy}&bot_id=${bot_id}`,
              "_self"
            )
          }
          className="flex items-center gap-4 cursor-pointer py-4 mt-16 sm:mt-0 "
        >
          <img src={front_arrow} className="w-8 rotate-[180deg]" />
          <span className="font-semibold">Stats</span>
        </div>
        <div className="bg-secondary mt-4 sm:mt-0">
          <table className=" bg-primary rounded-lg w-full text-md font-bold ">
            <thead>
              <tr className="border-b-[0.5px] border-border whitespace-nowrap">
                <th className="text-left px-8 py-4">
                  <div className="flex gap-1 items-center">
                    <span className="">Pair</span>
                    <img className="w-4 h-4" src={direction} />
                  </div>
                </th>
                <th className="text-left px-8 py-4">
                  <div className="flex gap-1 items-center">
                    <span className="font-semibold">Profit (%)</span>
                    <img className="w-4 h-4" src={direction} />
                  </div>
                </th>
                <th className="text-left px-8 py-4">
                  <div className="flex gap-1 items-center">
                    <span className="font-semibold">Volume</span>
                    <img className="w-4 h-4" src={direction} />
                  </div>
                </th>
                <th className="text-left px-8 py-4">
                  <div className="flex gap-1 items-center">
                    <span className="font-semibold">Status</span>
                    <img className="w-4 h-4" src={direction} />
                  </div>
                </th>
                <th className="text-left px-8 py-4">
                  <div className="flex gap-1 items-center">
                    <span className="font-semibold">SO</span>
                    {/* <img className="w-4 h-4" src={direction} /> */}
                  </div>
                </th>
                <th className="text-left px-8 py-4">
                  <div className="flex gap-1 items-center">
                    <span className="font-semibold">Closed on</span>
                    <img className="w-4 h-4" src={direction} />
                  </div>
                </th>
              </tr>
            </thead>

            <tbody className="">
              {deals.length > 0 ? (
                deals.map((item, index) => (
                  <tr
                    className="whitespace-nowrap text-sm opacity-70 border-b-[0.5px] border-border"
                    key={item.id}
                    onClick={() =>
                      window.open(
                        `/dashboard/bot-stats/pair/chart?strategy=${strategy}&bot_id=${bot_id}&profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`,
                        "_self"
                      )
                    }
                  >
                    <td className="px-8 py-4">
                      <div>
                        <div className="flex gap-2 items-center">
                          <img
                            className="w-4"
                            src={
                              coins.filter(
                                (e) => e.name === item.from_currency
                              )[0].value
                            }
                          />
                          <span>{item.pair}</span>
                        </div>
                        <span className="mt-1">{item.bot_name}</span>
                      </div>
                    </td>
                    <td className="px-8 py-4">
                      <div className="flex flex-col text-green-light">
                        <span className="border-b-[0.5px] py-1">
                          <span>{item.bought_amount}</span>
                        </span>
                        <span>
                          {item.final_profit} {item.from_currency}
                        </span>
                        <span>{item.final_profit_percentage}%</span>
                      </div>
                    </td>
                    <td className="px-8 py-4">
                      <div className="flex flex-col">
                        <span>{item.bought_volume}</span>
                        {/* <span>+1.32%</span> */}
                      </div>
                    </td>
                    <td className="px-8 py-4">{item.status}</td>
                    <td className="px-8 py-4">
                      Filled:{item.completed_safety_orders_count}
                    </td>
                    <td className="px-8 py-4">
                      <div className="flex flex-col">
                        <span>ID: {item.id}</span>
                        <span>
                          Start: {formatDate(item.created_at, "MMM DD, YYYY")}
                        </span>
                        <span>
                          End:{" "}
                          {item.closed_at
                            ? formatDate(item.closed_at, "MMM DD, YYYY")
                            : "-"}
                        </span>
                        <span>
                          Duration:{" "}
                          {item.closed_at
                            ? `${
                                calculateTimeDuration(
                                  item.created_at,
                                  item.closed_at
                                ).hours
                              }hrs ${
                                calculateTimeDuration(
                                  item.created_at,
                                  item.closed_at
                                ).minutes
                              } mins`
                            : ""}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={6}
                    className="text-center sm:text-lg mt-20 font-semibold opacity-50 font-public-sans py-12"
                  >
                    Nothing is here yet!
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </Wrapper>
    </MainWrapper>
  );
}

export default Pair;
