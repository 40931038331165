import React, { useState } from "react";
import Wrapper from "../DashboardWrapper";
import { ColorCard } from "./CardLarge";
import front_arrow from "../../../assets/icons/front-arrow.png";
import {
  CB001,
  CB002,
  CB003,
  CB004,
  CB005,
  CB006,
  CB007,
  CB008,
  CB009,
  CB010,
  CB011
} from "../../DataRoom/Main";

function List({ setDetails, details, exchange, profit_coin, deposit_coin }) {
  return (
    <Wrapper className={"px-4 py-12 rounded-md relative bg-[#292C3C]"}>
      <div className="flex flex-col gap-4 mt-12 sm:px-4 sm:mt-0">
        <div
          onClick={() => window.open("/dashboard/select-bot", "_self")}
          className="flex items-center gap-4 cursor-pointer w-[15%]"
        >
          <img src={front_arrow} className="w-8 rotate-[180deg]" />
          <span className="font-semibold whitespace-nowrap">
            All strategies
          </span>
        </div>
        <span className="font-semibold opacity-50 sm:text-lg sm:px-12">
          Which strategy would you like to activate:
        </span>
      </div>
      <div className="py-8 sm:px-12">
        <div className="flex flex-wrap gap-8 sm:gap-14">
          {profit_coin === "btc" && deposit_coin === "btc" && (
            <>
              <ColorCard
                {...CB001}
                onClick={() => {
                  setDetails(CB001);
                }}
              />
              <ColorCard
                {...CB002}
                onClick={() => {
                  setDetails(CB002);
                }}
              />
            </>
          )}

          {deposit_coin === "btc" &&
            (profit_coin === "usdc" || profit_coin === "usdt") && (
              <>
                <ColorCard
                  {...CB003}
                  onClick={() => {
                    setDetails(CB003);
                  }}
                />
              </>
            )}

          {profit_coin === "eth" && deposit_coin === "eth" && (
            <>
              <ColorCard
                {...CB004}
                onClick={() => {
                  setDetails(CB004);
                }}
              />
              <ColorCard
                {...CB005}
                onClick={() => {
                  setDetails(CB005);
                }}
              />
            </>
          )}

          {deposit_coin === "eth" &&
            (profit_coin === "usdc" || profit_coin === "usdt") && (
              <>
                <ColorCard
                  {...CB006}
                  onClick={() => {
                    setDetails(CB006);
                  }}
                />
              </>
            )}

          {profit_coin === "sol" && deposit_coin === "sol" && (
            <>
              <ColorCard
                {...CB007}
                onClick={() => {
                  setDetails(CB007);
                }}
              />
              <ColorCard
                {...CB008}
                onClick={() => {
                  setDetails(CB008);
                }}
              />
            </>
          )}

          {deposit_coin === "sol" &&
            (profit_coin === "usdc" || profit_coin === "usdt") && (
              <>
                <ColorCard
                  {...CB009}
                  onClick={() => {
                    setDetails(CB009);
                  }}
                />
              </>
            )}

          {(profit_coin === "usdt" && deposit_coin === "usdt") ||
          (profit_coin === "usdc" && deposit_coin === "usdc") ||
          (profit_coin === "usdc" && deposit_coin === "usdt") ||
          (profit_coin === "usdt" && deposit_coin === "usdc") ? (
            <>
              <ColorCard
                {...CB010}
                onClick={() => {
                  setDetails(CB010);
                }}
              />
              <ColorCard
                {...CB011}
                onClick={() => {
                  setDetails(CB011);
                }}
              />
              {/* <ColorCard
                {...CB012}
                onClick={() => {
                  setDetails(CB012);
                }}
              /> */}
            </>
          ) : (
            <></>
          )}

          {["binance", "bybit", "okx"].includes(exchange) && (
            <>
              <ColorCard
                {...CB001}
                onClick={() => {
                  setDetails(CB001);
                }}
              />
              <ColorCard
                {...CB002}
                onClick={() => {
                  setDetails(CB002);
                }}
              />
              <ColorCard
                {...CB003}
                onClick={() => {
                  setDetails(CB003);
                }}
              />
              <ColorCard
                {...CB004}
                onClick={() => {
                  setDetails(CB004);
                }}
              />
              <ColorCard
                {...CB005}
                onClick={() => {
                  setDetails(CB005);
                }}
              />
              <ColorCard
                {...CB006}
                onClick={() => {
                  setDetails(CB006);
                }}
              />
              <ColorCard
                {...CB007}
                onClick={() => {
                  setDetails(CB007);
                }}
              />
              <ColorCard
                {...CB008}
                onClick={() => {
                  setDetails(CB008);
                }}
              />
              <ColorCard
                {...CB009}
                onClick={() => {
                  setDetails(CB009);
                }}
              />
              <ColorCard
                {...CB010}
                onClick={() => {
                  setDetails(CB010);
                }}
              />
              <ColorCard
                {...CB011}
                onClick={() => {
                  setDetails(CB011);
                }}
              />
              {/* <ColorCard
                {...CB012}
                onClick={() => {
                  setDetails(CB012);
                }}
              /> */}
            </>
          )}

          {["gate_io"].includes(exchange) && (
            <>
              <ColorCard
                {...CB001}
                onClick={() => {
                  setDetails(CB001);
                }}
              />
              <ColorCard
                {...CB002}
                onClick={() => {
                  setDetails(CB002);
                }}
              />
              <ColorCard
                {...CB003}
                onClick={() => {
                  setDetails(CB003);
                }}
              />
              <ColorCard
                {...CB004}
                onClick={() => {
                  setDetails(CB004);
                }}
              />

              <ColorCard
                {...CB006}
                onClick={() => {
                  setDetails(CB006);
                }}
              />
              <ColorCard
                {...CB007}
                onClick={() => {
                  setDetails(CB007);
                }}
              />
              <ColorCard
                {...CB009}
                onClick={() => {
                  setDetails(CB009);
                }}
              />
              <ColorCard
                {...CB010}
                onClick={() => {
                  setDetails(CB010);
                }}
              />
              <ColorCard
                {...CB011}
                onClick={() => {
                  setDetails(CB011);
                }}
              />
              {/* <ColorCard
                {...CB012}
                onClick={() => {
                  setDetails(CB012);
                }}
              /> */}
            </>
          )}

          {["binance_us", "coinbase"].includes(exchange) && (
            <>
              <ColorCard
                {...CB001}
                onClick={() => {
                  setDetails(CB001);
                }}
              />

              <ColorCard
                {...CB004}
                onClick={() => {
                  setDetails(CB004);
                }}
              />

              <ColorCard
                {...CB007}
                onClick={() => {
                  setDetails(CB007);
                }}
              />

              <ColorCard
                {...CB010}
                onClick={() => {
                  setDetails(CB010);
                }}
              />

              {/* <ColorCard
                {...CB012}
                onClick={() => {
                  setDetails(CB012);
                }}
              /> */}
            </>
          )}

          {["kraken", "gemini"].includes(exchange) && (
            <>
              <ColorCard
                {...CB001}
                onClick={() => {
                  setDetails(CB001);
                }}
              />

              <ColorCard
                {...CB004}
                onClick={() => {
                  setDetails(CB004);
                }}
              />

              <ColorCard
                {...CB007}
                onClick={() => {
                  setDetails(CB007);
                }}
              />

              <ColorCard
                {...CB010}
                onClick={() => {
                  setDetails(CB010);
                }}
              />
            </>
          )}

          {["bitget"].includes(exchange) && (
            <>
              <ColorCard
                {...CB011}
                onClick={() => {
                  setDetails(CB011);
                }}
              />
            </>
          )}
        </div>
      </div>

      <div className="flex items-center justify-end gap-16 sm:px-16">
        <a href="/dashboard/data-room">
          {`Data room`}
        </a>
        {/* <a href="/">clear selections</a> */}
        {/* <a href="/">faq</a> */}
        {/* <a href="/">wiki</a> */}
      </div>
    </Wrapper>
  );
}

export default List;
