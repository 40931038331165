import React, { useState } from "react";
import Wrapper from "../DashboardWrapper";
import bitcoin from "../../../assets/icons/bitcoin.svg";
import ethereum from "../../../assets/icons/ethereum.svg";
import solana from "../../../assets/icons/solana.svg";
import usdc from "../../../assets/icons/usd-coin.svg";
import tether from "../../../assets/icons/tether-usdt.svg";
import logo from "../../../assets/images/logo.png";
import okx from "../../../assets/icons/OKX.svg";
import kraken from "../../../assets/icons/Kraken.svg";
import gate_io from "../../../assets/icons/full-gate.svg";
import coinbase from "../../../assets/icons/coinbase.svg";
import gemini from "../../../assets/icons/gemini.svg";
import kucoin from "../../../assets/icons/kucoin.svg";
import binance from "../../../assets/icons/binance.svg";
import binance_us from "../../../assets/icons/Binance-us.svg";
import bitget from "../../../assets/icons/Bitget.svg";
import bybit from "../../../assets/icons/bybit.svg";
import front_arrow from "../../../assets/icons/front-arrow.png";
import { toast } from "react-toastify";

function Main() {
  const [depositCoin, setdepositCoin] = useState("");
  const [profitCoin, setprofitCoin] = useState("");
  const [exchangeNetwork, setexchangeNetwork] = useState("");
  const deposit_profit_hover = depositCoin
    ? ""
    : "pointer-events-none filter grayscale";
  const deposit_exchange_hover = depositCoin
    ? "pointer-events-none filter grayscale"
    : "";
  const exchange_selected_hover = exchangeNetwork
    ? "pointer-events-none filter grayscale"
    : "";

  const handleResult = () => {
    if (depositCoin && !profitCoin) {
      toast.error("Select a profit coin to continue", {
        position: "top-center",
      });
      return;
    }
    if (!depositCoin && profitCoin) {
      toast.error("Select a deposit coin to continue", {
        position: "top-center",
      });
      return;
    }
    if (!depositCoin && !profitCoin && !exchangeNetwork) {
      toast.error(
        "Select at least a deposit, profit coin or exchange to continue",
        {
          position: "top-center",
        }
      );
      return;
    }
    window.open(
      `/dashboard/bot-activate?profit_coin=${profitCoin}&deposit_coin=${depositCoin}&exchange=${exchangeNetwork}`,
      "_self"
    );
  };

  return (
    <Wrapper className={"px-4 py-12 rounded-xl relative bg-[#292C3C]"}>
      <div className="flex items-baseline justify-between gap-12 mt-12 sm:items-center sm:justify-center sm:gap-0 sm:py-8">
        <div>
          <h2 className="w-full font-semibold text-center sm:text-left sm:text-2xl text-md opacity-60">
            EXPLORE OUR BOTS AND THEIR PERFORMANCE
          </h2>
          <h3 className="px-4 text-sm text-center sm:text-left sm:text-lg opacity-60 w-full sm:w-[60%] sm:px-0">
            Discover our automated trading strategies by either selecting your favorite exchange or selecting the assets you would like to trade with.
          </h3>
        </div>
        <div
          onClick={() => handleResult()}
          className="flex items-center gap-4 transition-transform duration-300 cursor-pointer hover:scale-125"
        >
          <span className="font-semibold">See results</span>
          <img src={front_arrow} className="w-8" />
        </div>
      </div>

      <div className="flex flex-col gap-6 mt-6 sm:flex-row sm:justify-evenly sm:mt-0 sm:px-24">
        <div className="relative flex flex-col justify-between flex-1 bg-secondary max-w-[600px] bg-opacity-80 rounded-3xl">
          <div className="flex flex-col px-4 py-6 sm:p-14 ">
            <span className="font-semibold sm:text-xl text-md">step 1</span>
            <div className="flex flex-col sm:flex-row sm:justify-between">
              <div className="flex flex-col ">
                <span className="sm:text-lg opacity-60">
                  select your currency deposit:
                </span>
                <span className="sm:text-lg opacity-60">
                  {`(it takes money to make money)`}
                </span>
              </div>

              <div className="flex flex-col gap-8 mt-4 sm:mt-0">
                <div className="flex flex-row gap-8">
                  <div
                    onClick={() => {
                      //if (exchangeNetwork) return;
                      if (depositCoin === "btc") {
                        setdepositCoin("");
                        setprofitCoin("");
                        return;
                      }
                      setexchangeNetwork("");
                      setdepositCoin("btc");
                      setprofitCoin("");
                    }}
                    className={`sm:w-16 sm:h-16 w-12 h-12 ${
                      depositCoin == "btc"
                        ? "border-2 rounded-3xl"
                        : "bg-transparent"
                    } ${
                      !exchangeNetwork &&
                      "hover:scale-125 transition-transform duration-300"
                    } ${exchange_selected_hover} flex justify-center items-center`}
                  >
                    <img
                      alt="bitcoin"
                      src={bitcoin}
                      className={`sm:w-12 w-10`}
                    />
                  </div>

                  <div
                    onClick={() => {
                      if (exchangeNetwork) return;
                      if (depositCoin === "eth") {
                        setdepositCoin("");
                        setprofitCoin("");
                        return;
                      }
                      setexchangeNetwork("");
                      setdepositCoin("eth");
                      setprofitCoin("");
                    }}
                    className={`sm:w-16 sm:h-16 w-12 h-12 ${
                      depositCoin == "eth"
                        ? "border-2 rounded-3xl"
                        : "bg-transparent"
                    } ${
                      !exchangeNetwork &&
                      "hover:scale-125 transition-transform duration-300"
                    } ${exchange_selected_hover} flex justify-center items-center`}
                  >
                    <img
                      alt="ethereum"
                      src={ethereum}
                      className={`sm:w-12 w-10`}
                    />
                  </div>

                  <div
                    onClick={() => {
                      if (exchangeNetwork) return;
                      if (depositCoin === "sol") {
                        setdepositCoin("");
                        setprofitCoin("");
                        return;
                      }
                      setexchangeNetwork("");
                      setdepositCoin("sol");
                      setprofitCoin("");
                    }}
                    className={`sm:w-16 sm:h-16 w-12 h-12 ${
                      depositCoin == "sol"
                        ? "border-2 rounded-3xl"
                        : "bg-transparent"
                    } ${
                      !exchangeNetwork &&
                      "hover:scale-125 transition-transform duration-300"
                    } ${exchange_selected_hover} flex justify-center items-center`}
                  >
                    <img alt="solana" src={solana} className={`sm:w-10 w-8`} />
                  </div>
                </div>
                <div className="flex items-center gap-8 sm:justify-center">
                  <div
                    onClick={() => {
                      if (exchangeNetwork) return;
                      if (depositCoin === "usdt") {
                        setdepositCoin("");
                        setprofitCoin("");
                        return;
                      }
                      setexchangeNetwork("");
                      setdepositCoin("usdt");
                      setprofitCoin("");
                    }}
                    className={`sm:w-16 sm:h-16 w-12 h-12 ${
                      depositCoin == "usdt"
                        ? "border-2 rounded-3xl"
                        : "bg-transparent"
                    } ${
                      !exchangeNetwork &&
                      "hover:scale-125 transition-transform duration-300"
                    } ${exchange_selected_hover} flex justify-center items-center`}
                  >
                    <img alt="usdt" src={tether} className={`sm:w-12 w-10`} />
                  </div>
                  <div
                    onClick={() => {
                      if (exchangeNetwork) return;
                      if (depositCoin === "usdc") {
                        setdepositCoin("");
                        setprofitCoin("");
                        return;
                      }
                      setexchangeNetwork("");
                      setdepositCoin("usdc");
                      setprofitCoin("");
                    }}
                    className={`sm:w-16 sm:h-16 ${
                      depositCoin == "usdc"
                        ? "border-2 rounded-3xl"
                        : "bg-transparent"
                    } ${
                      !exchangeNetwork &&
                      "hover:scale-125 transition-transform duration-300"
                    } ${exchange_selected_hover} flex justify-center items-center`}
                  >
                    <img alt="usdc" src={usdc} className={`sm:w-12 w-10`} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex rounded-3xl flex-col bg-[rgba(217,217,217,0.17)] sm:absolute sm:bottom-0  w-full py-2 px-4 sm:p-14">
            <span className="font-semibold sm:text-xl opacity-60">step 2</span>

            <div className="flex flex-col sm:flex-row sm:justify-between mt- sm:items-center">
              <span className="sm:text-lg opacity-60 w-[60%] sm:w-">
                select the currency you would like to receive profits in:
              </span>

              <div className="flex flex-col gap-8 mt-4 sm:mt-0">
                <div className="flex flex-row gap-8">
                  <div
                    className={`sm:w-16 sm:h-16 w-12 h-12 ${
                      profitCoin == "btc"
                        ? "border-2 rounded-3xl"
                        : "bg-transparent"
                    } ${
                      !exchangeNetwork &&
                      "hover:scale-125 transition-transform duration-300"
                    } ${
                      depositCoin === "btc" || depositCoin === ""
                        ? ""
                        : "pointer-events-none filter grayscale"
                    } ${deposit_profit_hover} ${exchange_selected_hover} cursor-pointer flex justify-center items-center`}
                  >
                    <img
                      onClick={() => {
                        setprofitCoin("btc");
                      }}
                      alt="bitcoin"
                      src={bitcoin}
                      className={`sm:w-12 w-10`}
                    />
                  </div>

                  <div
                    className={`sm:w-16 sm:h-16 w-12 h-12 ${
                      profitCoin == "eth"
                        ? "border-2 rounded-3xl"
                        : "bg-transparent"
                    } ${
                      !exchangeNetwork &&
                      "hover:scale-125 transition-transform duration-300"
                    }  ${
                      depositCoin === "eth" || depositCoin === ""
                        ? ""
                        : "pointer-events-none filter grayscale"
                    } ${deposit_profit_hover} ${exchange_selected_hover} cursor-pointer flex justify-center items-center`}
                  >
                    <img
                      onClick={() => {
                        setprofitCoin("eth");
                      }}
                      alt="ethereum"
                      src={ethereum}
                      className={`sm:w-12 w-10`}
                    />
                  </div>

                  <div
                    className={`sm:w-16 sm:h-16 w-12 h-12 ${
                      profitCoin == "sol"
                        ? "border-2 rounded-3xl"
                        : "bg-transparent"
                    } ${
                      !exchangeNetwork &&
                      "hover:scale-125 transition-transform duration-300"
                    }  ${
                      depositCoin === "sol" || depositCoin === ""
                        ? ""
                        : "pointer-events-none filter grayscale"
                    } ${deposit_profit_hover} ${exchange_selected_hover} cursor-pointer flex justify-center items-center`}
                  >
                    <img
                      onClick={() => {
                        setprofitCoin("sol");
                      }}
                      alt="solana"
                      src={solana}
                      className={`sm:w-10 w-8`}
                    />
                  </div>
                </div>
                <div className="flex items-center gap-8 sm:justify-center ">
                  <div
                    className={`sm:w-16 sm:h-16 w-12 h-12 ${
                      profitCoin == "usdt"
                        ? "border-2 rounded-3xl"
                        : "bg-transparent"
                    } ${
                      !exchangeNetwork &&
                      "hover:scale-125 transition-transform duration-300"
                    }  ${
                      depositCoin === "usdt" ||
                      depositCoin === "usdc" ||
                      depositCoin === "" ||
                      depositCoin === "btc" ||
                      depositCoin === "sol" ||
                      depositCoin === "eth"
                        ? ""
                        : "pointer-events-none filter grayscale"
                    } ${deposit_profit_hover} ${exchange_selected_hover} cursor-pointer flex justify-center items-center`}
                  >
                    <img
                      onClick={() => {
                        setprofitCoin("usdt");
                      }}
                      alt="tether"
                      src={tether}
                      className={`sm:w-12 w-10`}
                    />
                  </div>

                  <div
                    className={`sm:w-16 sm:h-16 w-12 h-12 ${
                      profitCoin == "usdc"
                        ? "border-2 rounded-3xl"
                        : "bg-transparent"
                    } ${
                      !exchangeNetwork &&
                      "hover:scale-125 transition-transform duration-300"
                    }  ${
                      depositCoin === "usdt" ||
                      depositCoin === "usdc" ||
                      depositCoin === "" ||
                      depositCoin === "btc" ||
                      depositCoin === "sol" ||
                      depositCoin === "eth"
                        ? ""
                        : "pointer-events-none filter grayscale"
                    } ${deposit_profit_hover} ${exchange_selected_hover} cursor-pointer flex justify-center items-center`}
                  >
                    <img
                      onClick={() => {
                        setprofitCoin("usdc");
                      }}
                      alt="usdc"
                      src={usdc}
                      className="w-10 sm:w-12"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col flex-1 py-8 bg-secondary max-w-[600px] rounded-3xl sm:py-16">
          <span className="px-4 text-sm font-semibold sm:text-lg opacity-60 sm:p-12">
            or simply select your favorite exchange and see what we have
            available for it.
          </span>

          <div className="flex flex-col gap-6 mt-6 sm:gap-10 sm:mt-0">
            <div aria-disabled className="flex self-center gap-8 sm:gap-20">
              <div
                onClick={() => {
                  if (exchangeNetwork === "okx") {
                    setexchangeNetwork("");
                    return;
                  }
                  setexchangeNetwork("okx");
                  setdepositCoin("");
                  setprofitCoin("");
                }}
                className={`sm:w-20 sm:h-20 w-16 h-16 ${
                  exchangeNetwork == "okx"
                    ? "border-2 rounded-3xl"
                    : "bg-transparent"
                } ${
                  !profitCoin &&
                  !depositCoin &&
                  "hover:scale-125 transition-transform duration-300"
                } ${deposit_exchange_hover} flex justify-center`}
              >
                <img alt="okx" src={okx} className="sm:w-16 w-14" />
              </div>
              <div
                onClick={() => {
                  if (exchangeNetwork === "binance") {
                    setexchangeNetwork("");
                    return;
                  }
                  setexchangeNetwork("binance");
                  setdepositCoin("");
                  setprofitCoin("");
                }}
                className={`sm:w-20 sm:h-20 w-16 h-16 ${
                  exchangeNetwork == "binance"
                    ? "border-2 rounded-3xl"
                    : "bg-transparent"
                }  ${
                  !profitCoin &&
                  !depositCoin &&
                  "hover:scale-125 transition-transform duration-300"
                }  ${deposit_exchange_hover}  flex justify-center`}
              >
                <img alt="binance" src={binance} className={`sm:w-16 w-14`} />
              </div>

              <div
                onClick={() => {
                  if (exchangeNetwork === "bybit") {
                    setexchangeNetwork("");
                    return;
                  }
                  setexchangeNetwork("bybit");
                  setdepositCoin("");
                  setprofitCoin("");
                }}
                className={`sm:w-20 sm:h-20 w-16 h-16 ${
                  exchangeNetwork == "bybit"
                    ? "border-2 rounded-3xl"
                    : "bg-transparent"
                }  ${
                  !profitCoin &&
                  !depositCoin &&
                  "hover:scale-125 transition-transform duration-300"
                } ${deposit_exchange_hover}  flex justify-center`}
              >
                <img alt="bybit" src={bybit} className={`sm:w-16 w-14`} />
              </div>
            </div>

            <div className="flex self-center gap-8 sm:gap-20">
              <div
                onClick={() => {
                  if (exchangeNetwork === "coinbase") {
                    setexchangeNetwork("");
                    return;
                  }
                  setexchangeNetwork("coinbase");
                  setdepositCoin("");
                  setprofitCoin("");
                }}
                className={`sm:w-20 sm:h-20 w-16 h-16 ${
                  exchangeNetwork == "coinbase"
                    ? "border-2 rounded-3xl"
                    : "bg-transparent"
                }  ${
                  !profitCoin &&
                  !depositCoin &&
                  "hover:scale-125 transition-transform duration-300"
                }  ${deposit_exchange_hover}  flex justify-center`}
              >
                <img alt="coinbase" src={coinbase} className={`sm:w-16 w-14`} />
              </div>
              <div
                onClick={() => {
                  if (exchangeNetwork === "kraken") {
                    setexchangeNetwork("");
                    return;
                  }
                  setexchangeNetwork("kraken");
                  setdepositCoin("");
                  setprofitCoin("");
                }}
                className={`sm:w-20 sm:h-20 w-16 h-16 ${
                  exchangeNetwork == "kraken"
                    ? "border-2 rounded-3xl"
                    : "bg-transparent"
                }  ${
                  !profitCoin &&
                  !depositCoin &&
                  "hover:scale-125 transition-transform duration-300"
                }  ${deposit_exchange_hover}  flex justify-center`}
              >
                <img alt="kraken" src={kraken} className={`sm:w-16 w-14`} />
              </div>
              <div
                onClick={() => {
                  if (exchangeNetwork === "bitget") {
                    setexchangeNetwork("");
                    return;
                  }
                  setexchangeNetwork("bitget");
                  setdepositCoin("");
                  setprofitCoin("");
                }}
                className={`sm:w-20 sm:h-20 w-16 h-16 ${
                  exchangeNetwork == "bitget"
                    ? "border-2 rounded-3xl"
                    : "bg-transparent"
                }  ${
                  !profitCoin &&
                  !depositCoin &&
                  "hover:scale-125 transition-transform duration-300"
                }  ${deposit_exchange_hover}  flex justify-center`}
              >
                <img alt="bitget" src={bitget} className={`sm:w-16 w-14`} />
              </div>
            </div>
            <div className="flex self-center gap-8 sm:gap-20">
              <div
                onClick={() => {
                  if (exchangeNetwork === "kucoin") {
                    setexchangeNetwork("");
                    return;
                  }
                  setexchangeNetwork("kucoin");
                  setdepositCoin("");
                  setprofitCoin("");
                }}
                className={`sm:w-20 sm:h-20 w-16 h-16 ${
                  exchangeNetwork == "kucoin"
                    ? "border-2 rounded-3xl"
                    : "bg-transparent"
                }  ${
                  !profitCoin &&
                  !depositCoin &&
                  "hover:scale-125 transition-transform duration-300"
                }  ${deposit_exchange_hover}  flex justify-center`}
              >
                <img alt="kucoin" src={kucoin} className={`sm:w-16 w-14`} />
              </div>
              <div
                onClick={() => {
                  if (exchangeNetwork === "gate_io") {
                    setexchangeNetwork("");
                    return;
                  }
                  setexchangeNetwork("gate_io");
                  setdepositCoin("");
                  setprofitCoin("");
                }}
                className={`sm:w-20 sm:h-20 w-16 h-16 ${
                  exchangeNetwork == "gate_io"
                    ? "border-2 rounded-3xl"
                    : "bg-transparent"
                }  ${
                  !profitCoin &&
                  !depositCoin &&
                  "hover:scale-125 transition-transform duration-300"
                }  ${deposit_exchange_hover}  flex justify-center`}
              >
                <img alt="gate_io" src={gate_io} className={`sm:w-16 w-14`} />
              </div>
              <div
                onClick={() => {
                  if (exchangeNetwork === "binance_us") {
                    setexchangeNetwork("");
                    return;
                  }
                  setexchangeNetwork("binance_us");
                  setdepositCoin("");
                  setprofitCoin("");
                }}
                className={`sm:w-20 sm:h-20 w-16 h-16 ${
                  exchangeNetwork == "binance_us"
                    ? "border-2 rounded-3xl"
                    : "bg-transparent"
                }  ${
                  !profitCoin &&
                  !depositCoin &&
                  "hover:scale-125 transition-transform duration-300"
                }  ${deposit_exchange_hover}  flex justify-center`}
              >
                <img
                  alt="binance_us"
                  src={binance_us}
                  className={`sm:w-16 w-14`}
                />
              </div>
            </div>
            <div
              onClick={() => {
                if (exchangeNetwork === "gemini") {
                  setexchangeNetwork("");
                  return;
                }
                setexchangeNetwork("gemini");
                setdepositCoin("");
                setprofitCoin("");
              }}
              className={`sm:w-20 sm:h-20 w-16 h-16 ${
                exchangeNetwork == "gemini"
                  ? "border-2 rounded-3xl"
                  : "bg-transparent"
              }  ${
                !profitCoin &&
                !depositCoin &&
                "hover:scale-125 transition-transform duration-300"
              }  ${deposit_exchange_hover}  flex justify-center self-center`}
            >
              <img alt="gemini" src={gemini} className={`sm:w-16 w-14`} />
            </div>
          </div>
        </div>
      </div>
      <div className="absolute hidden sm:bottom-4 right-8 sm:block">
        <img className="w-6" src={logo} alt="logo" />
      </div>

      <div className="flex justify-center gap-4 px-4 py-2 text-sm font-semibold sm:left-40 sm:gap-8 sm:px-0 opacity-60 sm:text-lg">
        <div
          onClick={() => {
            setdepositCoin("");
            setprofitCoin("");
            setexchangeNetwork("");
          }}
          className="cursor-pointer "
        >
          clear selections
        </div>
        <a
          href="https://help.cryptobots.io/"
          target="_blank"
          className=""
        >
          Knowledge base
        </a>
        <a
          href="https://help.cryptobots.io/faq"
          target="_blank"
        >
          Frequently Asked Questions
        </a>
      </div>
    </Wrapper>
  );
}

export default Main;
