import React, { useState } from "react";
import Wrapper from "../Dashboard/DashboardWrapper";
import Loader from "../Loader";
import Header from "../Dashboard/Header";
import { useLoading, useLocalStorage } from "../../hooks";
import Sidebar from "../Dashboard/Sidebar";
import MainWrapper from "../MainWrapper";
import { useLocation } from "react-router-dom";
import bitcoin from "../../assets/icons/bitcoin.svg";
import ethereum from "../../assets/icons/ethereum.svg";
import solana from "../../assets/icons/solana.svg";
import usdc from "../../assets/icons/usd-coin.svg";
import tether from "../../assets/icons/tether-usdt.svg";
import { WithdrawBtn } from "./Main";
import { SendOTP, Withdraw } from "../../API";
import { toast } from "react-toastify";
import { confirmSignIn } from "../../utilities";

const coin_map = {
  sol: [solana],
  eth: [ethereum],
  btc: [bitcoin],
  usd: [usdc, tether],
};

function Index() {
  const [showSideBar, setShowSideBar] = useState(false);
  const [isLoading, startLoading, stopLoading] = useLoading();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const coin = queryParams.get("coin");
  const balance = queryParams.get("bal");
  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState("");
  const [otp, setOtp] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [authToken, setAuthToken] = useLocalStorage("authToken", "");

  const sendOtp = async () => {
    startLoading();
    const res = await SendOTP(authToken);
    if (res?.success) {
      setOtpSent(true);
      toast.success("OTP sent to your email address");
    } else {
      confirmSignIn(res.message, window, setAuthToken);
      toast.error("Error sending OTP", { position: "top-center" });
    }
    stopLoading();
  };

  const withdraw = async () => {
    if (parseFloat(amount) > balance) {
      toast.error("Insufficient balance");
      return;
    }
    startLoading();
    const res = await Withdraw(
      {
        amount: parseFloat(amount),
        walletAddress: address,
        currency: coin,
        otp,
      },
      authToken
    );

    if (res?.success) {
      toast.success("Withdrawal request received", { position: "top-center" });
    } else {
      confirmSignIn(res.message, window, setAuthToken);
      toast.error(res.message, {
        position: "top-center",
      });
    }
    stopLoading();
  };

  return (
    <MainWrapper>
      <Sidebar
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        active={{ id: "4", href: "/dashboard/affliate" }}
      />
      <Header showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <Wrapper className={"px-4 py-4 rounded-xl relative bg-[#292C3C]"}>
        <Loader isLoading={isLoading} />
        <div className="bg-[#151924] py-8 sm:px-6 rounded-xl flex justify-center items-center mt-16">
          <div className="flex flex-col gap-4 w-[80%]">
            <div className="justify-center items-center flex">
              {coin_map[coin].length > 1 ? (
                <div className="flex gap-4">
                  <img className="w-16" src={coin_map[coin][0]} />
                  <img className="w-16" src={coin_map[coin][1]} />
                </div>
              ) : (
                <img className="w-16" src={coin_map[coin][0]} />
              )}
            </div>
            <div className="flex flex-col gap-2">
              <span className="uppercase">{coin} Balance</span>
              <input
                disabled
                className="py-2 focus:outline-none px-1 text-black"
                value={`${balance} ${coin.toLocaleUpperCase()}`}
              />
            </div>
            <div className="flex flex-col gap-2">
              <span className="uppercase">{coin} Address</span>
              <input
                className="py-2 focus:outline-none px-1 text-black"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>

            <div className="flex flex-col sm:flex-row gap-4 justify-between">
              <div className="flex flex-col gap-2 sm:w-[90%]">
                <span className="uppercase">Amount to withdraw ({coin})</span>
                <input
                  className="py-2 focus:outline-none px-1 text-black "
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  maxLength={6}
                />
              </div>

              <div className="flex flex-col gap-2">
                <span className="uppercase">2FA OTP</span>

                <input
                  className="py-2 focus:outline-none px-1 text-black"
                  value={otp}
                  onChange={(e) => setOtp(e.target.value)}
                  maxLength={6}
                />
              </div>

              <div
                onClick={sendOtp}
                className="border px-4 rounded-sm p flex items-center justify-center h-10 self-end cursor-pointer"
              >
                {otpSent ? "Resend" : "Send OTP"}
              </div>
            </div>

            <WithdrawBtn
              style={"py-3 text-center rounded-md mt-4 font-bold"}
              coin={coin}
              onClick={withdraw}
            />
          </div>
        </div>
      </Wrapper>
    </MainWrapper>
  );
}

export default Index;
