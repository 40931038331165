import React from "react";
import twitter from "../../../assets/icons/twitter.svg";
import instagram from "../../../assets/icons/instagram.svg";
import youtube from "../../../assets/icons/youtube.svg";
import facebook from "../../../assets/icons/facebook.svg";
import tiktok from "../../../assets/icons/tiktok.svg";
import logo from "../../../assets/images/logo.png";

function Footer({ locale }) {
  return (
    <div className="py-12 bg-primary">
      <div className="flex flex-col items-center justify-center gap-6 text-center sm:text-2xl text-md p-clamp-padding-feature">
        <img className="w-10 rounded-full sm:w-14" src={logo} alt="logo" />
        <span className="text-gray-400">
          {locale === "es" ? "conéctate con nosotros" : "connect with us"}
        </span>
      </div>

      <div className="flex items-center justify-center gap-6 opacity-60">
        <a className="w-6" href="https://twitter.com/cryptobotsio">
          <img src={twitter} alt="x" />
        </a>
        <a className="w-6" href="https://www.instagram.com/cryptobots.io">
          <img src={instagram} alt="instagram" />
        </a>
        <a className="w-6" href="https://www.tiktok.com/@cryptobots.io">
          <img src={tiktok} alt="tiktok" />
        </a>
        {/* <a className="w-6" href="/">
          <img src={reddit} alt="reddit" />
        </a> */}
        {/* <a className="w-6" href="/">
          <img src={telegram} alt="telegram" />
        </a> */}
        <a className="w-6" href="https://www.youtube.com/@cryptobotsio">
          <img src={youtube} alt="youtube" />
        </a>
        <a className="w-6" href="https://www.facebook.com/cryptobots.io">
          <img src={facebook} alt="facebook" />
        </a>
      </div>

      <div className="flex flex-col items-center justify-center gap-4 mt-8 sm:gap-6 sm:text-lg sm:flex-row sm:mt-14 opacity-60">
        <a
          href="https://help.cryptobots.io/faq"
          target="_blank"
          className=""
          rel="noreferrer"
        >
          {locale === "es"
            ? "Preguntas frecuentes"
            : "Frequently Asked questions"}
        </a>
        <a href="/dashboard/data-room" target="_self">
          {locale === "es" ? "Sala de datos" : "Data Room"}
        </a>
        <a href="https://help.cryptobots.io" target="_blank" rel="noreferrer">
          {locale === "es" ? "Base de conocimientos" : "Knowledge Base"}
        </a>
        <a href="#">Blog</a>
      </div>

      <div className="flex justify-center mt-14 w-[90%] md:w-[23%] mx-auto">
        <span className="text-center sm:text-md opacity-60">
          {locale === "es" ? (
            <span>
              © 2024 Cryptobots.io LLC Miami Beach, FL 33141. <br /> Todos los
              derechos reservados.
            </span>
          ) : (
            <span>
              © 2024 Cryptobots.io LLC Miami Beach, FL 33141. <br />
              All Rights Reserved.
            </span>
          )}
        </span>
      </div>
    </div>
  );
}

export default Footer;
