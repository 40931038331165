import React from "react";
import selling from "../../../assets/icons/selling 1.svg";
import buy from "../../../assets/icons/buy.svg";
import sell from "../../../assets/icons/Group.svg";

function Strategy({ locale }) {
  return (
    <div className="relative flex flex-col h-[130vh] gap-12 text-black bg-cover background-image bg-chart-white p-clamp-padding-strategy">
      <div className="flex flex-col items-center w-full gap-4 ">
        <span className="text-xl font-bold text-center sm:text-4xl">
          {locale === "es"
            ? "Nuestra estrategia comercial es simple. Compra barato, vende caro."
            : "Our trading strategy is simple. Buy low, sell high."}
        </span>
        <span className="sm:text-2xl text-md">
          {locale === "es"
            ? "La volatilidad es nuestro papá. "
            : "Volatility is our daddy. "}
        </span>
        <a
          href="/dashboard/data-room"
          target="_self"
          className="sm:text-md text-center font-medium w-[90%] sm:w-[80%] lg:w-[50%]"
        >
          {locale === "es"
            ? "cryptobots.io aprovecha cada oportunidad que brinda el mercado para comprar más del activo deseado. Cuanto más baja, más compra y, finalmente, vende a un precio más alto que su precio promedio, generando ganancias constantes aprovechando la volatilidad del mercado."
            : "cryptobots.io takes every opportunity the markets gives, to buy more of your desired asset. The lower it goes the more it buys, and eventually, it sells higher than its average price generating constant profit using the markets volatility."}
        </a>
      </div>

      <div className="absolute hidden sm:block left-[10%] sm:left-[20%] md:left-[15%] top-[50%] sm:top-[10%]">
        <img src={selling} alt="sell icon" className="w-24 max-lg:w-20" />
      </div>

      <div className="absolute left-[13%] hidden xl:block md:left-[10%] bottom-[40%] sm:bottom-[10%]">
        <img src={buy} alt="buy icon" className="w-10" />
      </div>
      <div className="absolute left-[13%] hidden xl:block md:left-[38%] bottom-[40%] sm:bottom-[10%]">
        <img src={buy} alt="buy icon" className="w-10" />
      </div>
      <div className="absolute right-[70%] md:right-[15%] bottom-[20%] sm:bottom-[20%] lg:bottom-[35%]">
        <img src={buy} alt="buy icon" className="w-10" />
      </div>
      <div className="absolute left-[40%] md:left-[8%] top-[40%] sm:top-[30%]">
        <img src={sell} alt="buy icon" className="w-10" />
      </div>
      {/* <div className="absolute hidden xl:block right-[13%] md:right-[45%] lg:top-[40%] sm:top-[40%]">
        <img src={sell} alt="buy icon" className="w-10" />
      </div> */}
      <div className="absolute hidden xl:block right-[13%] md:right-[20%] top-[40%] sm:top-[40%] lg:top-[25%]">
        <img src={sell} alt="buy icon" className="w-10" />
      </div>
      <div className="absolute self-center font-semibold text-center p-5 text-md bottom-0 min-[380px]:bottom-0">
        <a href="/dashboard/data-room">
          {locale === "es"
            ? "> Sala de datos <"
            : `> Data Room <`}
        </a>
        <div className="flex justify-center gap-8 mt-3 max-sm:mt-8">
          <a
            href="https://help.cryptobots.io/"
            target="_blank"
            rel="noreferrer"
          >
            {locale === "es" ? "Ayuda" : "Help"}
          </a>
          {/* <a>FAQ</a> */}
        </div>
      </div>
    </div>
  );
}

export default Strategy;
