import React from "react";
import bitcoin_full from "../../../assets/icons/bitcoin-full.svg";
import ethereum_full from "../../../assets/icons/ethereum-full.svg";
import solana_full from "../../../assets/icons/solana-full.svg";
import usdc_full from "../../../assets/icons/usd-coin-full.svg";
import tether_full from "../../../assets/icons/tether-full.svg";
import logo from "../../../assets/images/logo.png";
import okx from "../../../assets/icons/OKX.svg";
import kraken from "../../../assets/icons/Kraken.svg";
import gate_io from "../../../assets/icons/full-gate.svg";
import coinbase from "../../../assets/icons/coinbase.svg";
import gemini from "../../../assets/icons/gemini.svg";
import kucoin from "../../../assets/icons/kucoin.svg";
import binance from "../../../assets/icons/binance.svg";
import binance_us from "../../../assets/icons/Binance-us.svg";
import bitget from "../../../assets/icons/Bitget.svg";
import bybit from "../../../assets/icons/bybit.svg";

function Partners({ locale, setLocale }) {
  return (
    <div className="flex sm:flex-row flex-col justify-center">
      <div className="bg-secondary flex-1 bg-opacity-35 flex flex-col sm:gap-20 gap-12 sm:pl-40 sm:pr-20 px-6 sm:py-20 py-12">
        <span className="sm:text-xl text-md opacity-60">
          {locale === "es"
            ? "Aproveche el poder de la automatización del comercio y benefíciese de la volatilidad del mercado de las criptomonedas las 24 horas del día, los 7 días de la semana."
            : "Take advantage of the power of trading automation and benefit from crypto's 24/7 market volatilty."}
        </span>
        <div className="mx-auto grid grid-cols-2 gap-x-10 gap-y-10">
          <div className="">
            <img alt="bitcoin" src={bitcoin_full} className="sm:w-28 w-24" />
          </div>
          <div className=""></div>
          <div className=""></div>
          <div className="">
            <img alt="tether" src={tether_full} className="sm:w-36 w-28" />
          </div>
          <div className="">
            <img alt="ethereum" src={ethereum_full} className="sm:w- w-28" />
          </div>
          <div className=""></div>
          <div className=""></div>
          <div className=" ">
            <img alt="solana" src={solana_full} className="sm:w- w-28" />
          </div>
          <div className="">
            <img alt="usdc" src={usdc_full} className="sm:w- w-28" />
          </div>
        </div>
        <span className="sm:text-lg text-md opacity-60">
          {locale === "es"
            ? "Genere un ingreso de efectivo pasivo denominado en los criptoactivos más confiables y líquidos del mundo."
            : "Generate a passive cash inflow denominated in the most trusted and liquid crypto assets in the world."}
        </span>
      </div>

      <div className="bg-secondary flex-1 flex flex-col gap-16 sm:pr-40 sm:pl-40 py-20 relative px-6">
        <span className="sm:text-xl text-md opacity-60">
          {locale === "es"
            ? "Nuestro sistema de comercio automatizado puede ejecutarse en los sitios más confiables y confiables del mundo. intercambios líquidos."
            : "Our automated trading system can run in the worlds most trusted and liquid exchanges."}
        </span>

        <div className="flex flex-col gap-10">
          <div className=" flex justify-center w-full">
            <img alt="okx" src={okx} className="sm:w-20 w-16" />
          </div>
          <div className="flex justify-between w-full">
            <img alt="binance" src={binance} className="sm:w-28 w-20" />
            <img alt="bybit" src={bybit} className="sm:w-20 w-16" />
          </div>
          <div className="flex justify-center w-full">
            <img alt="coinbase" src={coinbase} className="sm:w-28 w-20" />
          </div>
          <div className="flex justify-between w-full mt-4">
            <img alt="kraken" src={kraken} className="sm:w-28 w-20" />
            <img alt="bitget" src={bitget} className="sm:w-24 w-20" />
          </div>

          <div className="flex justify-between w-full mt-12 gap-2 sm:gap-0">
            <img alt="gemini" src={gemini} className="sm:w-28 w-20" />
            <img alt="gate-io" src={gate_io} className="sm:w-28 w-20" />
            <img alt="binance-us" src={binance_us} className="sm:w-24 w-20" />
          </div>

          <div className="flex justify-center w-full mt-16">
            <img alt="kucoin" src={kucoin} className="sm:w-28 w-20" />
          </div>
        </div>
        <div className="flex items-center gap-4 opacity-100 absolute bottom-3 right-8">
          <img className="sm:w-6 w-4" src={logo} alt="logo" />
          <span className="font-bold sm:text-lg">cryptobots.io</span>
        </div>
      </div>
    </div>
  );
}

export default Partners;
