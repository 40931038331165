import React, { useEffect, useState } from "react";
import { useLoading, useLocalStorage } from "../../hooks";
import { toast } from "react-toastify";
import { DeleteAccount, FetchUser, UpdateProfile } from "../../API";
import avatar from "../../assets/icons/profile-icon.png";
import { confirmSignIn } from "../../utilities";
import checkmark from "../../assets/icons/checkmark.png";
import checkbox_active from "../../assets/icons/checkbox-active.png";
import checkbox_inactive from "../../assets/icons/checkbox-inactive.png";
import { DragToUpload } from "../../utilities/upload";

function AccountSettings({ setActiveTab }) {
  const [user, setUser] = useLocalStorage("user", {});
  const [authToken, setAuthToken] = useLocalStorage("authToken", "");
  const [isLoading, startLoading, stopLoading] = useLoading();
  const [name, setName] = useState(user.name);
  const [email, setEmail] = useState(user.email);
  const [weekly_report, setWeeklyReport] = useState(
    user.notifications?.weekly_report
  );
  const [withdraw_activity, setWithdrawActivity] = useState(
    user.notifications?.withdraw_activity
  );
  const [password_change, setPasswordChange] = useState(
    user.notifications?.password_change
  );
  const [payment_success, setPaymentSuccess] = useState(
    user.notifications?.payment_success
  );

  const [file, setFile] = useState("");

  const handleSaveChanges = async () => {
    startLoading();
    const res = await UpdateProfile(
      {
        name,
        avatar: file || user.avatar,

        notifications: {
          weekly_report,
          withdraw_activity,
          password_change,
          payment_success,
        },
      },
      authToken
    );
    if (res?.success) {
      const fetchUser = await FetchUser(authToken);
      setUser(fetchUser.user);
      toast.success("Saved successfully", { position: "top-center" });
    } else {
      confirmSignIn(res.message, window, setAuthToken);
      toast.error(res.message, { position: "top-center" });
    }
    stopLoading();
  };

  return (
    <div className="bg-[#151924] sm:w-[60%] py-8 sm:px-6 px-4 rounded-xl flex flex-col gap-6 text-gray-400 ">
      <div className="border-b-[0.5px] opacity-15 mt-4" />

      <>
        <div className="text-lg font-semibold">Personal Information</div>

        <div onClick={() => ""} className={`sm:px-4 px-1 flex items-center gap-6 `}>
          <img
            className="w-24 rounded-full"
            src={file || user.avatar || avatar}
          />
          <DragToUpload
            startLoading={startLoading}
            stopLoading={stopLoading}
            setFile={setFile}
          />
        </div>

        <div className="flex flex-col gap-2">
          <span className="opacity-60">Name</span>
          <input
            className="bg-primary sm:w-[80%] focus:outline-none focus:ring-0 rounded-md px-2 py-2"
            value={name}
            placeholder="Name"
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="flex flex-col gap-2">
          <span className="opacity-60">Email</span>
          <div className="flex gap-2 bg-primary rounded-md sm:w-[80%] justify-between">
            <input
              className="bg-transparent w-[50%] focus:outline-none focus:ring-0 rounded-md px-2 py-2"
              value={email}
              placeholder="Email"
              disabled
              onChange={(e) => setEmail(e.target.value)}
            />
            {user.isEmailVerified && (
              <div className="flex items-center gap-1 mr-4">
                <img className="w-6" src={checkmark} />
                <span className="whitespace-nowrap text-green-light text-md">
                  Email verified
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="border-b-[0.5px] opacity-15 mt-4" />
      </>

      <>
        <div className="text-lg font-semibold">Notifications</div>
        <div className="text-lg font-semibold opacity-70">
          Choose type of notification you want to receive
        </div>

        <div className="grid grid-cols-2 mt-2">
          <div className="flex flex-col gap-6">
            <div className="flex gap-2">
              <img
                onClick={() => setWithdrawActivity(!withdraw_activity)}
                className="w-6"
                src={withdraw_activity ? checkbox_active : checkbox_inactive}
              />
              <span className="font-semibold">Withdraw Activity</span>
            </div>

            <div className="flex gap-2">
              <img
                onClick={() => setWeeklyReport(!weekly_report)}
                className="w-6"
                src={weekly_report ? checkbox_active : checkbox_inactive}
              />
              <span className="font-semibold">Weekly Report</span>
            </div>
          </div>

          <div className="flex flex-col gap-6">
            <div className="flex gap-2">
              <img
                onClick={() => setPasswordChange(!password_change)}
                className="w-6"
                src={password_change ? checkbox_active : checkbox_inactive}
              />
              <span className="font-semibold">Password change</span>
            </div>
            <div className="flex gap-2">
              <img
                onClick={() => setPaymentSuccess(!payment_success)}
                className="w-6"
                src={payment_success ? checkbox_active : checkbox_inactive}
              />
              <span className="font-semibold">Payment Success</span>
            </div>
            {/* <div className="flex gap-2">
              <img className="w-6" src={checkbox_inactive} />
              <span className="font-semibold">Top Up Success</span>
            </div> */}
          </div>
        </div>

        <div className="border-b-[0.5px] opacity-15 mt-4" />
      </>

      <div className="flex justify-between items-center">
        <div
          onClick={() => setActiveTab("")}
          className="self-end font-semibold px-4 py-1 rounded-md cursor-pointer text-white"
        >
          Discard changes
        </div>
        <div
          onClick={handleSaveChanges}
          className="self-end font-semibold bg-green-light px-4 py-1 rounded-md cursor-pointer text-white"
        >
          Save changes
        </div>
      </div>
    </div>
  );
}

export default AccountSettings;
