import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { ColorCard } from "./CardLarge";
import Wrapper from "../DashboardWrapper";
import front_arrow from "../../../assets/icons/front-arrow.png";
import { toast } from "react-toastify";
import SelectExchange from "../SelectBot/SelectExchange";
import { CreateBot } from "../../../API";
import { useLoading, useLocalStorage } from "../../../hooks";
import Loader from "../../Loader";
import { confirmSignIn } from "../../../utilities";

const info_list = [
  "Funds: Verify that your account has the minimum required funds before sending API keys.",
  "API Key Permissions: Double-check that all permissions on the API keys are correctly set.",
  "Correct Wallet: Ensure funds are in the correct wallet within the exchange.",
  "Automatic Detection: The system automatically detects newly deposited base assets and starts trading. No notification to us is necessary.",
  "Trailing Sell Orders: Absence of visible sell orders may be due to trailing sell functionality.",
  "Bot Operation: Bots are considered operational when there are active orders.",
  "If you don't see any active orders, please contact support. Note: There's a cooldown period of approximately 20 minutes between trades.",
  "For spot strategies, do not withdraw or convert the quoted asset. Example: If you are profiting in USD by trading against BTC, then your quoted asset would be BTC, Please do not withdraw the BTC from your balance.",
  "Monitor your profits via Cryptobots.io app dashboard and Your exchange's order history",
  "For derivatives, partial withdrawals above 5% are not recommended without stopping the system first.",
  "Request a bot stop before making large partial withdrawals.",
  "Acceptable Partial Withdrawals: Partial withdrawals below 5% are acceptable without notification or system halt.",
  "Full Withdrawals: We recommend only making full withdrawals after stopping the bot.",
];

function Details({ details }) {
  const [isLoading, startLoading, stopLoading] = useLoading();
  const [authToken, setAuthToken] = useLocalStorage("authToken", "");
  const location = useLocation();
  // Parse the search string to extract query parameters
  const queryParams = new URLSearchParams(location.search);
  const [apiKey, setApiKey] = useState("");
  const [apiSecret, setApiSecret] = useState("");
  const [apiPassphrase, setApiPassphrase] = useState("");
  const [agree, setAgree] = useState(false);
  const [exchangeNetwork, setexchangeNetwork] = useState(
    queryParams.get("exchange")
  );

  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const termsRef = useRef(null);

  const handleScroll = () => {
    const { scrollTop, scrollHeight, clientHeight } = termsRef.current;
    if (scrollTop + clientHeight >= scrollHeight) {
      setIsScrolledToBottom(true);
    }
  };

  useEffect(() => {
    const termsDiv = termsRef.current;
    termsDiv.addEventListener("scroll", handleScroll);
    return () => {
      termsDiv.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Get individual query parameters by key
  const profitCoin = queryParams.get("profit_coin");
  const depositCoin = queryParams.get("deposit_coin");
  //const exchangeNetwork = queryParams.get("exchange");

  console.log(profitCoin, depositCoin, exchangeNetwork);

  //   useEffect(() => {
  //     if (bot_id) setBot(data.filter((e) => e.name == bot_id));
  //   });

  const activateBot = async () => {
    console.log(details.strategy.name);
    startLoading();
    const res = await CreateBot(
      {
        strategy: details.strategy.name,
        profitCurrency: profitCoin,
        depositCurrency: depositCoin,
        exchange: exchangeNetwork,
        apiKey,
        apiSecret,
        apiPassphrase,
      },
      authToken
    );

    console.log(res);

    if (res?.success) {
      toast.success(res.message, { position: "top-center" });
      setTimeout(() => {
        window.open("/dashboard/bot-active", "_self");
      }, 3000);
    } else {
      confirmSignIn(res.message, window, setAuthToken);
      toast.error(res?.message || "Error occured", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  const handleValidation = () => {
    if (!exchangeNetwork) {
      toast.error("Please select an exchange network", {
        position: "top-center",
      });
      return;
    }
    if (!apiKey) {
      toast.error("Api key required", {
        position: "top-center",
      });
      return;
    }
    if (!apiSecret) {
      toast.error("Api secret required", {
        position: "top-center",
      });
      return;
    }
    if (
      ["kucoin", "okx", "bitget"].includes(exchangeNetwork) &&
      !apiPassphrase
    ) {
      toast.error("Passphrase is required.", {
        position: "top-center",
      });
      return;
    }
    if (!agree) {
      toast.error(
        "Please read carefully & agree to our terms of service below.",
        {
          position: "top-center",
        }
      );
      return;
    }

    activateBot();
  };

  return (
    <Wrapper className={"px-4 py-12 rounded-md relative bg-[#292C3C]"}>
      <Loader isLoading={isLoading} />
      <div
        onClick={() =>
          window.open(
            `/dashboard/bot-activate?profit_coin=${profitCoin}&deposit_coin=${depositCoin}&exchange=${exchangeNetwork}`,
            "_self"
          )
        }
        className="flex items-center gap-4 cursor-pointer w-[15%] py-2 mt-12 sm:mt-0"
      >
        <img src={front_arrow} className="w-8 rotate-[180deg]" />
        <span className="font-semibold whitespace-nowrap">Select Bot</span>
      </div>
      <div className="grid gap-8 sm:grid-cols-2 sm:px-12">
        <div className="py-8 overflow-x-hidden sm:py-12 sm:px-2 rounded-xl">
          <ColorCard
            bg={details.bg}
            titleColor={details.titleColor}
            lightCircle={details.lightCircle}
            textLight={details.textLight}
            textDark={details.textDark}
            logo={details.logo}
            denomination={details.denomination}
            wallets={details.wallets}
            strategy={details.strategy}
          />
          <div className="mt-12 text-lg ">
            IMPORTANT INFORMATION ABOUT THIS STRATEGY
            <div className="flex flex-col ">
              <div className="mt-4">
                {/* <span className="py-2 mb-2 font-bold">Before Starting:</span> */}
                <ul className="pl-4 pr-10 space-y-2 list-disc">
                  {info_list.map((item, index) => (
                    <li key={index} className="text-gray-400 text-md">
                      {item}
                    </li>
                  ))}
                </ul>
                <p className="mt-5 text-gray-400 text-md">
                  <strong className="text-white">Disclaimer:</strong>

                  <p className="mt-4">
                  Our system includes advanced monitoring tools that detect any manual interference with the trading process. If it is discovered that a user is manually altering, adjusting, or otherwise getting involved in the trades executed by our system, their account will be immediately closed. This policy is strictly enforced to maintain the integrity and optimal performance of our automated trading strategies. By using our services, you agree to abide by this policy and understand that violations will result in account termination.
                  </p>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="">
          <div className="py-8 border rounded-lg">
            <div className="py-8 font-semibold text-center sm:text-lg opacity-70">
              connect your exchange to activate the bot.
            </div>

            <SelectExchange
              setexchangeNetwork={setexchangeNetwork}
              exchangeNetwork={exchangeNetwork}
            />
          </div>
          <div className="mt-8 flex flex-col gap-4 text-[#505155]">
            <div className="flex flex-col gap-2">
              <span>API KEY</span>
              <input
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                className="px-1 py-2 focus:outline-none"
              />
            </div>
            <div className="flex flex-col gap-2">
              <span>SECRET</span>
              <input
                className="px-1 py-2 focus:outline-none"
                value={apiSecret}
                onChange={(e) => setApiSecret(e.target.value)}
              />
            </div>

            {["okx", "bitget", "kucoin"].includes(exchangeNetwork) && (
              <div className="flex flex-col gap-2">
                <span>PASSPHRASE</span>
                <input
                  className="px-1 py-2 focus:outline-none"
                  value={apiPassphrase}
                  onChange={(e) => setApiPassphrase(e.target.value)}
                />
              </div>
            )}

            <div className="flex items-center gap-2 ">
              <input
                type="checkbox"
                id="checkbox"
                className="w-4 h-4 mt-1 bg-white border-2 rounded-sm shrink-0"
                onChange={(e) => setAgree(!agree)}
                value={agree}
                // disabled={!isScrolledToBottom}
              />
              <label htmlFor="checkbox">
                Read our terms of service carefully.
              </label>
            </div>

            <div
              ref={termsRef}
              className="h-[200px] border p-3 overflow-y-auto text-gray-400"
            >
              <h2 className="font-bold">Risk Disclosure:</h2>
              <p className="text-md">
                Before activating this automated trading strategy, it is crucial
                that you understand and acknowledge the inherent risks
                associated with cryptocurrency trading and automated systems.
                Cryptocurrency markets are highly volatile, and automated
                trading strategies, while potentially beneficial, carry
                significant risks. These include, but are not limited to,
                potential loss of capital—up to and including your entire
                investment. Some of our automated trading strategies are
                deployed in derivatives markets. Users must be aware that, due
                to convexity and leverage, positions may be subject to
                liquidation if the required margin is not maintained. At
                [cryptobots.io](http://cryptobots.io/), we implement a
                conservative risk management approach, limiting leverage to a
                maximum of 1.5x out of 125x possible. This provides an
                approximate 40% safety buffer before additional margin may be
                required. It is important to note that, while we provide
                educational resources and automated tools, we do not offer
                personalized investment advice. Past performance of our
                strategies does not guarantee future results. The effectiveness
                of the automated system can be impacted by various factors,
                including market conditions, technical issues, or unforeseen
                events. By activating this automated trading strategy, you
                affirm that: 1. You have read, understood, and accepted the
                complete risk disclosure available in the "Legal" section at
                [help.cryptobots.io](http://help.cryptobots.io/). 2. You
                acknowledge the risks associated with cryptocurrency trading and
                automated strategies. 3. You accept full responsibility for the
                outcomes of the automated trading strategy. 4. You have
                carefully considered your financial situation, risk tolerance,
                and investment goals. 5. You understand that only registered
                intermediaries can legally provide specific trading advice or
                facilitate transactions. We strongly recommend consulting with a
                qualified financial professional before engaging in any trading
                activity, including the activation of this automated strategy.
                Activation of this strategy indicates your informed consent to
                proceed, acknowledging all associated risks and
                responsibilities.
              </p>
            </div>

            <div
              onClick={handleValidation}
              className={`flex items-center justify-center font-bold mt-4 py-2 ${details.bg} cursor-pointer`}
            >
              Activate Bot
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Details;
