import React from "react";
import forward_arrow from "../../assets/icons/forward-arrow.png";
//import green_path from "../../assets/icons/path.svg";
import logo from "../../assets/images/logo.png";
import kraken from "../../assets/icons/Kraken.svg";
import gate_io from "../../assets/icons/full-gate.svg";
import coinbase from "../../assets/icons/coinbase.svg";
import gemini from "../../assets/icons/gemini.svg";
import kucoin from "../../assets/icons/kucoin.svg";
import binance from "../../assets/icons/binance.svg";
import binance_us from "../../assets/icons/Binance-us.svg";
import bitget from "../../assets/icons/Bitget.svg";
import bybit from "../../assets/icons/bybit.svg";
import okx from "../../assets/icons/OKX.svg";
import bitcoin from "../../assets/icons/bitcoin.svg";
import ethereum from "../../assets/icons/ethereum.svg";
import solana from "../../assets/icons/solana.svg";
import usdc from "../../assets/icons/usd-coin.svg";
import tether from "../../assets/icons/tether-usdt.svg";
import { BOT_INTENT } from "../../utilities";
import { toast } from "react-toastify";
import { useLoading, useLocalStorage } from "../../hooks";
import { UpdateBot } from "../../API";

const exchanges = [
  { name: "kraken", value: kraken },
  { name: "gate_io", value: gate_io },
  { name: "coinbase", value: coinbase },
  { name: "gemini", value: gemini },
  { name: "kucoin", value: kucoin },
  { name: "binance", value: binance },
  { name: "binance_us", value: binance_us },
  { name: "bitget", value: bitget },
  { name: "bybit", value: bybit },
  { name: "okx", value: okx },
];

const coins = [
  { name: "btc", value: bitcoin },
  { name: "eth", value: ethereum },
  { name: "sol", value: solana },
];

const bot_select = (name) => {
  let result = { bg: "bg-orange-light", text: "text-orange-dark" };
  if (name === "Ethereum bots")
    result = { bg: "bg-blue-light", text: "text-blue-dark" };
  if (name === "Solana bots")
    result = { bg: "bg-purple-light", text: "text-purple-dark" };
  if (name === "USD stable coin bots")
    result = { bg: "bg-green-light", text: "text-green-dark" };
  return result;
};

function Card({ card, item, onClick }) {
  console.log(item);
  const [isLoading, startLoading, stopLoading] = useLoading();
  const [authToken] = useLocalStorage("authToken", "");

  const updateBot = async (intent) => {
    startLoading();
    const res = await UpdateBot({ intent, bot_id: item._id }, authToken);
    console.log(res);
    if (res?.success) {
      //setData(res.result);
      toast.success(res.message, { position: "top-center" });
    } else {
      toast.error(res?.message || "Unable to fetch data", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  return (
    <div className=" w-80">
      <div
        className={`border-8 rounded-2xl border-[#62688F] py-2 px-2 flex flex-col gap-2 ${
          bot_select(card.bot).bg
        }`}
      >
        <div className="flex justify-between">
          <div className="flex items-center gap-2">
            <img className="w-6" src={logo} alt="logo" />
            <span className="font-medium text-lg text-secondary">
              cryptobots.io
            </span>
          </div>

          <div className="flex flex-col">
            <span className={`text-3xl ${bot_select(card.bot).text}`}>
              {card.strategy.name}
            </span>
          </div>
        </div>

        <div
          onClick={onClick}
          className="bg-secondary rounded-xl py-4 flex justify-evenly cursor-pointer min-h-32"
        >
          <div className="flex flex-col gap-2 ">
            <span className={`text-[#bcc1e1] text-md`}>Deposit</span>
            <div className="flex">
              <div className="flex gap-1">
                {["usdc", "usdt"].includes(item.depositCurrency) ? (
                  <div className="">
                    <img className="w-8" src={tether} alt="exchange" />
                    <img className="w-8 ml-6" src={usdc} alt="exchange" />
                  </div>
                ) : (
                  <img
                    className="w-10"
                    src={
                      coins.filter((e) => e.name === item.depositCurrency)[0]
                        .value
                    }
                    alt="exchange"
                  />
                )}
              </div>
            </div>
          </div>

          <div className="flex items-center gap-4">
            <img className="w-4" src={forward_arrow} alt="forward_arrow" />
            <img className="w-10 rounded-full" src={logo} alt="logo" />
            <img className="w-4 " src={forward_arrow} alt="forward_arrow" />
          </div>

          <div className="flex flex-col gap-2">
            <span className={`text-[#bcc1e1] text-md`}>Profit in</span>
            <div className="flex">
              <div className="flex gap-1">
                {["usdc", "usdt"].includes(item.profitCurrency) ? (
                  <div className="">
                    <img className="w-8" src={tether} alt="exchange" />
                    <img className="w-8 ml-6" src={usdc} alt="exchange" />
                  </div>
                ) : (
                  <img
                    className="w-10"
                    src={
                      coins.filter((e) => e.name === item.profitCurrency)[0]
                        .value
                    }
                    alt="exchange"
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="flex gap- justify-evenly gap-2">
          <div className="flex flex-col items-center justify-center">
            <span className={`text-secondary text-sm`}>Exchange</span>
            <div className="bg-secondary w-full h-8 rounded-full py-1 flex justify-center items-center px-6">
              <img
                className="w-12"
                src={exchanges.filter((e) => e.name === item.exchange)[0].value}
                alt="exchange"
              />
            </div>
          </div>

          <div className="flex flex-col items-center justify-center">
            <span className={`text-secondary text-sm whitespace-nowrap`}>
              Current Period ROI
            </span>
            <div className="bg-secondary w-full h-8 rounded-full py-1 text-center">
              <span className="text-green-light text-sm">
                ${item.current_roi}
              </span>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center">
            <span className={`text-primary text-sm`}>Bot Status</span>
            <div className="bg-secondary px-2 rounded-full py-1 w-full h-8">
              <span
                className={`${
                  item.status === "ACTIVE" ? "text-green-light" : "text-red"
                } text-sm text-center`}
              >
                {item.status === "ACTIVE" ? "Enabled" : "Disabled"}
              </span>
            </div>
          </div>
        </div>

        <div className="mt-4 flex flex-col gap-4">
          <div
            onClick={() => updateBot(BOT_INTENT.HARD_STOP)}
            className="bg-secondary w-64 rounded-full py-1 text-center flex flex-col cursor-pointer"
          >
            <span className="text-green-light text-sm">hard-stop bot</span>
            <span className="text-green-light text-[8px] whitespace-nowrap">
              deal cancelled immediately even while incurring loses
            </span>
          </div>
          <div
            onClick={() => updateBot(BOT_INTENT.SOFT_STOP)}
            className="bg-secondary w-64 rounded-full py-1 text-center flex flex-col cursor-pointer"
          >
            <span className="text-green-light text-sm">soft-stop bot</span>
            <span className="text-green-light text-[8px] whitespace-nowrap">
              bot will wait to finish current deal
            </span>
          </div>
          <div
            onClick={() => updateBot(BOT_INTENT.RESUME)}
            className="bg-secondary w-64 rounded-full py-1 text-center flex flex-col cursor-pointer"
          >
            <span className="text-green-light text-sm whitespace-nowrap">
              resume trading
            </span>
            <span className="text-green-light text-[8px] whitespace-nowrap">
              bot turn on again after been paused
            </span>
          </div>
          <div
            //onClick={() => updateBot(BOT_INTENT.RESUME)}
            className={`${
              item.marginCall ? "bg-red" : "bg-[#A8A8A8]"
            }  w-64 rounded-full py-1 text-center flex flex-col cursor-pointer`}
          >
            <span
              className={`${
                item.marginCall ? bot_select(card.bot).text : "text-[#5D5757]"
              } text-sm whitespace-nowrap font-semibold`}
            >
              Margin call
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Card;
