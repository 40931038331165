import React, { useState } from "react";
import Wrapper from "./DashboardWrapper";
import avatar from "../../assets/icons/profile-icon.png";
import { useAppContext } from "../../Context";
import { useLoading, useLocalStorage } from "../../hooks";
import { toast } from "react-toastify";
import Loader from "../Loader";
import { SignIn, SignUp } from "../../API";
import MainWrapper from "../MainWrapper";
import Header from "./Header";
import Sidebar from "./Sidebar";
import front_arrow from "../../assets/icons/front-arrow.png";
import { useLocation } from "react-router-dom";
import { confirmSignIn } from "../../utilities";

function Signup() {
  const [user] = useLocalStorage("user", {});
  const [authToken, setAuthToken] = useLocalStorage("authToken", "");
  const [isLoading, startLoading, stopLoading] = useLoading();
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  // Access location object using useLocation hook
  const location = useLocation();

  // Parse the search string to extract query parameters
  const queryParams = new URLSearchParams(location.search);

  // Get individual query parameters by key
  const referrer = queryParams.get("referrer");

  const handleSignup = async () => {
    startLoading();
    console.log("Email: ", email)
    const res = await SignUp({
      email,
      password,
      name,
      referrer: referrer || ""
    });
    if (res?.success) {
      toast.success(res.message, { position: "top-center" });
      setTimeout(() => {
        window.open("/dashboard", "_self");
      }, 2000);
    } else{
      confirmSignIn(res.message, window, setAuthToken);
      toast.error(res.message, { position: "top-center" });
    }
    stopLoading();
    
  };

  const handleValidation = () => {
    if (!name) {
      toast.error("Name required", { position: "top-center" });
      return;
    }
    if (!email) {
      toast.error("Email required", { position: "top-center" });
      return;
    }
    if (!confirmEmail) {
      toast.error("Confirm email required", { position: "top-center" });
      return;
    }
    if (!password) {
      toast.error("Password required", { position: "top-center" });
      return;
    }
    if (!confirmPassword) {
      toast.error("Confirm Password required", { position: "top-center" });
      return;
    }
    if (email !== confirmEmail) {
      toast.error("Email must match Confirm Email", {
        position: "top-center",
      });
      return;
    }
    if (password !== confirmPassword) {
      toast.error("Password must match Confirm Password", {
        position: "top-center",
      });
      return;
    }
    handleSignup();
  };

  return (
    <MainWrapper>
      <Header />
      <Sidebar active={{ id: "1", href: "/dashboard" }} />
      <Wrapper className={"px-8 py-12 rounded-xl relative bg-[#292C3C]"}>
        <Loader isLoading={isLoading} />
        <div
          onClick={() => window.open("/dashboard", "_self")}
          className="flex items-center gap-4 py-8 cursor-pointer"
        >
          <img src={front_arrow} className="w-8 rotate-[180deg]" />
          <span className="font-semibold">Login</span>
        </div>
        <div className="">
          <div className="flex flex-col gap-4">
            <input
              placeholder="Enter name"
              className="px-2 py-1 bg-transparent focus:outline-none"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <input
              placeholder="Enter email"
              className="px-2 py-1 bg-transparent focus:outline-none"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              placeholder="Confirm email"
              className="px-2 py-1 bg-transparent focus:outline-none"
              value={confirmEmail}
              onChange={(e) => setConfirmEmail(e.target.value)}
            />

            <input
              placeholder="Enter password"
              className="px-2 py-1 bg-transparent focus:outline-none"
              value={password}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <input
              placeholder="Re-enter password"
              className="px-2 py-1 bg-transparent focus:outline-none"
              value={confirmPassword}
              type="password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <div className="flex items-center gap-8 mt-12 ">
              <div
                onClick={() => handleValidation()}
                className="flex items-center px-4 py-2 text-lg rounded-full cursor-pointer bg-green-dark"
              >
                Sign up
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </MainWrapper>
  );
}

export default Signup;
