import React, { useEffect, useRef, memo } from "react";

export function TradingViewWidget({ exchange, profit_coin, deposit_coin }) {
  const container = useRef();
  const isComponentMounted = useRef(true);
  let symbol = `${exchange.toUpperCase()}:${deposit_coin.toUpperCase()}${profit_coin.toUpperCase()}`;

  console.log(symbol);
  useEffect(() => {
    // Set isComponentMounted to false when the component unmounts
    return () => {
      isComponentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isComponentMounted.current) {
      const script = document.createElement("script");
      script.src =
        "https://s3.tradingview.com/external-embedding/embed-widget-advanced-chart.js";
      script.type = "text/javascript";
      script.async = true;
      script.innerHTML = `
      {
        "autosize": true,
        "symbol": "${symbol}",
        "interval": "D",
        "timezone": "Etc/UTC",
        "theme": "dark",
        "style": "1",
        "locale": "en",
        "withdateranges": true,
        "hide_side_toolbar": false,
        "allow_symbol_change": true,
        "calendar": false,
        "support_host": "https://www.tradingview.com" 
      }
      `;
      container.current.appendChild(script);
    }
  }, []);

  return <div ref={container} style={{ height: "80vh", width: "100%" }}></div>;
}

export default memo(TradingViewWidget);
