import React from "react";
import refer from "../../../assets/icons/012-rpa 1.svg";
import referPie from "../../../assets/icons/018-diagram 1.svg";
import mutual from "../../../assets/icons/mutual 1.svg";
import vidEng from "../../../assets/CBIO Animation Videos/Overview.mp4"
import vidEs from "../../../assets/CBIO Animation Videos Spanish/Overview Spanish_.mp4"

function How({ locale }) {
  return (
    <div className="bg-[#D9D9D9] bg-opacity-90 text-black p-clamp-padding-how lg:flex-row flex flex-col justify-center lg:justify-between items-center gap-12 px-4 py-6">
      <div className="w-full lg:w-2/3">
        <span className="text-lg font-semibold sm:text-2xl">
          {" "}
          {locale === "es"
            ? "¿Cómo funciona Cryptobots.io?"
            : "how does cryptobots.io work?"}
        </span>
        <div className="w-full mt-4 h-[300px] sm:h-[500px] bg-[#D9D9D9] rounded-3xl overflow-hidden relative">
          <video
            src={locale === 'es' ? vidEs : vidEng}
            controls
            className="absolute inset-0 object-cover w-full h-full"
          />
        </div>

      </div>
      <div className="flex flex-col w-[80%] sm:w-1/2 gap-20 py-12 font-semibold lg:w-1/3">
        <div className="flex flex-col items-center gap-5">
          <img src={refer} alt="refer" />
          <span className="text-center sm:text-lg">
            {locale === "es"
              ? "Queremos mostrarle que nuestro sistema realmente funciona, por lo que conectarse y usar cryptobots.io es gratis y fácil."
              : "We want to show you our system really works, so connecting and using cryptobots.io is free and easy!"}
          </span>
        </div>
        <div className="flex flex-col items-center gap-5">
          <img src={referPie} alt="calculator" />
          <span className="text-center sm:text-lg">
            {locale === "es"
              ? "Le pediremos el 33% de sus ganancias generadas sólo después de que esté ¡100% convencido de que funciona!"
              : "We will ask you for 33% of your generated profits only after you are 100% convinced it works!"}
          </span>
        </div>
        <div className="flex flex-col items-center gap-5">
          <img src={mutual} alt="bind" />
          <span className="text-center sm:text-lg">
            {locale === "es"
              ? "Si usted no gana dinero, nosotros no ganamos dinero. ¡Es así de simple!"
              : "If you dont make money, we dont make money. its that simple!"}
          </span>
        </div>
      </div>
    </div>
  );
}

export default How;
