import React, { useState } from "react";
import Wrapper from "../Dashboard/DashboardWrapper";
import { useLoading } from "../../hooks";
import Loader from "../Loader";
import MainWrapper from "../MainWrapper";
import Sidebar from "../Dashboard/Sidebar";
import Header from "../Dashboard/Header";
import { useLocation } from "react-router-dom";
import { TradingViewWidget } from "../TradingView/widget";
import front_arrow from "../../assets/icons/front-arrow.png";

function Chart() {
  const [showSideBar, setShowSideBar] = useState(false);
  const [isLoading, startLoading, stopLoading] = useLoading();
  const location = useLocation();

  // Parse the search string to extract query parameters
  const queryParams = new URLSearchParams(location.search);

  // Get individual query parameters by key
  const strategy = queryParams.get("strategy");
  const bot_id = queryParams.get("bot_id");
  const exchange = queryParams.get("exchange");
  const profit_coin = queryParams.get("profit_coin");
  const deposit_coin = queryParams.get("deposit_coin");

  return (
    <MainWrapper>
      <Sidebar
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        active={{ id: "2", href: "/dashboard/bot-active" }}
      />
      <Header showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <Wrapper
        className={
          "px-8 py-8 rounded-xl relative bg-[#292C3C] overflow-x-scroll"
        }
      >
        <Loader isLoading={isLoading} />
        <div
          onClick={() =>
            window.open(
              `/dashboard/bot-stats/pair?strategy=${strategy}&bot_id=${bot_id}`,
              "_self"
            )
          }
          className="flex items-center gap-4 cursor-pointer py-4"
        >
          <img src={front_arrow} className="w-8 rotate-[180deg]" />
          <span className="font-semibold">Trading deals</span>
        </div>
        <TradingViewWidget
          exchange={exchange}
          profit_coin={profit_coin}
          deposit_coin={deposit_coin}
        />
      </Wrapper>
    </MainWrapper>
  );
}

export default Chart;
