import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { useLocalStorage } from "../hooks";
import { UploadFile } from "../API";

export const DragToUpload = ({ startLoading, stopLoading, setFile }) => {
  //const [user, setUser] = useLocalStorage("user", {});
  const [authToken, setAuthToken] = useLocalStorage("authToken", "");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setUploadedFiles(acceptedFiles);
    },
  });

  useEffect(() => {
    if (uploadedFiles.length > 0) {
      upload();
    }
  }, [uploadedFiles]);

  const upload = async () => {
    startLoading();
    let formData = new FormData();
    formData.append("file", uploadedFiles[0]);
    const res = await UploadFile(formData, authToken);
    if (res?.success) {
      setFile(res.url);
      toast.success("Proceed to save your changes", { position: "top-center" });
    } else {
      toast.error(res.message, { position: "top-center" });
    }
    stopLoading();
  };

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />

      <div className="flex flex-col items-center gap-3">
        {/* <div className="w-6 h-6 cursor-pointer">
          <img src={UploadIcon} className="w-full h-full" alt={"bank-icon"} />
        </div> */}

        <div className="flex sm:flex-row flex-col gap-1 sm:items-center cursor-pointer">
          <div className="">
            <span className="font-semibold text-md text-primary-700 whitespace-nowrap">
              Click to upload
            </span>
          </div>
          <span className="font-semibold text-md text-grey-200 whitespace-nowrap">
            or drag and drop
          </span>
          
        </div>
        <ul>
          {uploadedFiles.map((file) => (
            <li key={file.name}>{file.name}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};
