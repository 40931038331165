import React from "react";

function Wrapper({ children, className }) {
  return (
    <div className={`z-30 text-sm sm:text-lg  ml-0 sm:ml-56 ${className}`}>
      {children}
    </div>
  );
}

export default Wrapper;
