import React, { useEffect } from "react";
import logo from "../../assets/images/logo.png";
import { IoMdClose } from "react-icons/io";

const tabs = [
  { id: "1", label: "Home", href: "/dashboard" },
  {
    id: "2",
    label: "My Bots",
    href: "/dashboard/bot-active",
  },
  
  { id: "3", label: "Profit Share", href: "/dashboard/profit-share" },
  { id: "4", label: "Affiliate Center", href: "/dashboard/affliate" },
  { id: "5", label: "Data Room", href: "/dashboard/data-room" },
  { id: "6", label: "Help", href: "/dashboard/help" },
  {
    id: "7",
    label: "Bot explorer",
    href: "/dashboard/select-bot",
  },
];

function Sidebar({ active, showSideBar, setShowSideBar }) {
  // if (!showSideBar) return <></>;
  return (
    <div
      className={`sm:mx-10 ${
        !showSideBar && "hidden sm:block"
      } fixed min-h-screen bg-primary sm:bg-transparent z-50  sm:z-10 flex flex-col gap-2 py-4 sm:py-0 sm:mt-6 px-6 w-2/3 sm:w-fit sm:px-0 `}
    >
      <div onClick={() => setShowSideBar(!showSideBar)} className="sm:hidden">
        <IoMdClose className="w-8 h-8" />
      </div>

      <div className="items-center hidden gap-4 opacity-100 sm:flex">
        <img className="w-4" src={logo} alt="logo" />
        <span className="text-lg font-semibold ">cryptobots.io</span>
      </div>

      <div className="lg:mt-16 sm:mt-0">
        {tabs.map((item) => (
          <div key={item.id} className={`py-2 flex flex-col opacity-60`}>
            <a href={item.href} className="text-lg font-semibold">
              {item.label}
            </a>
            {item.id == active.id && (
              <div className="border-b-[0.8px] ] mt-2 w-10" />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Sidebar;
