import React, { useState } from "react";
import profile_photo from "../../assets/images/profile.jpg";
import avatar from "../../assets/icons/profile-icon.png";
import { useAppContext } from "../../Context";
import { useLocalStorage } from "../../hooks";
import bell from "../../assets/icons/bell.png";
import { IoMdMenu, IoMdClose } from "react-icons/io";

function Header({ showSideBar, setShowSideBar }) {
  const [user] = useLocalStorage("user", {});
  const [authToken] = useLocalStorage("authToken", "");

  return (
    <div className="fixed right-0 z-40 px-2 py-4 w-fit sm:px-10 bg-primary sm:bg-transparent">
      <div className="flex items-center justify-between opacity-100 sm:justify-end">
        <div
          onClick={() => setShowSideBar(!showSideBar)}
          className="px-2 sm:hidden"
        >
          <IoMdMenu className="w-8 h-8" />
        </div>
        <div className="flex items-center gap-6 sm:gap-14">
          <img
            src={bell}
            className="w-6 cursor-pointer"
            onClick={() => window.open("/dashboard/notifications", "_self")}
            alt=""
          />
          <a href="/dashboard/help" target="_self">
            Help
          </a>
          <div
            onClick={() =>
              authToken ? window.open(`/dashboard/profile`, "_self") : ""
            }
            className={`w-10 flex justify-center items-center cursor-pointer`}
          >
            <img
              className="rounded-full"
              src={user?.avatar ? user.avatar : avatar}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
