import React from "react";
import logo from "../../../assets/images/logo.png";
import bloomberg from "../../../assets/icons/bloomberg.svg";
import cointelegraph from "../../../assets/icons/cointelegraph.svg";
import coindesk from "../../../assets/icons/coindesk.svg";
import reuters from "../../../assets/icons/reuters.svg";
import yahoo from "../../../assets/icons/yahoo.svg";
import wired from "../../../assets/icons/wired.svg";
import forbes from "../../../assets/icons/forbes.svg";
import Slider from "react-slick/lib/slider";
import threecommas from "../../../assets/images/3commas-1 1.png";

const sliderSettings = {
  dots: false,
  infinite: true,
  slidesToShow: 7,
  slidesToScroll: 1,
  autoplay: true,
  speed: 4000,
  autoplaySpeed: 4000,
  cssEase: "linear",
};

function Milestone({ locale }) {
  return (
    <div className="relative flex flex-col justify-between h-[120vh] sm:h-screen xl:h-[150vh] text-black bg-white bg-center bg-no-repeat bg-cover sm:bg-contain bg-chart-white p-clamp-padding-feature">
      <div>
        <div className="flex items-center justify-center gap-2 opacity-100">
          <img className="w-8" src={logo} alt="logo" />
          <span className="text-5xl font-semibold ">cryptobots.io</span>
        </div>

        <div className="flex flex-col justify-center gap-20 mx-auto lg:flex-row lg:gap-16 mt-14">
          <div className="text-[#78B159] flex flex-col mx-auto">
            <span className="text-5xl font-bold">
              {locale === "es" ? "$3.9+ millones" : "$3.9+ million"}
            </span>
            <span className="text-lg">
              {locale === "es"
                ? "en beneficios generados desde 2020"
                : "in profits generated since 2020"}
            </span>
          </div>
          <div className="flex flex-col mx-auto">
            <span className="text-5xl font-bold">$323,000+</span>
            <span className="text-lg">
              {locale === "es"
                ? "Pagado a nuestros socios afiliados."
                : "paid out to our affiliate partners."}{" "}
            </span>
          </div>
          <div className="flex flex-col mx-auto text-[#55ACEE]  gap-2">
            <span className="text-lg">
              {locale === "es"
                ? "ayudando a personas de más"
                : "helping people from over"}
            </span>
            <span className="text-5xl font-bold">
              {locale === "es" ? "42 países" : "42 countries"}
            </span>
            <span className="text-lg">
              {locale === "es"
                ? "lograr sus objetivos financieros."
                : "achieve their financial goals."}
            </span>
          </div>
        </div>
      </div>

      

      <div className="flex flex-col gap-5">
        <div
          onClick={() => window.open("/dashboard/select-bot", "_self")}
          className=" bg-[#1E2234] px-6 py-2 rounded-full text-xl text-white w-52 mx-auto text-center cursor-pointer"
        >
          {locale === "es" ? "panel" : "Dashboard"}
        </div>
        <div className="flex flex-col items-center gap-8 pb-8 mt-5">
          <span className="font-semibold text-center text-md">
            {locale === "es"
              ? "encuentre informes y artículos sobre el poder del comercio automatizado en:"
              : "find reports and articles about the power of automated trading in:"}{" "}
          </span>
        </div>

        <div className="lg:mt-5">
          <Slider {...sliderSettings}>
            <img className="w-8 px-16" src={coindesk} alt="coindesk" />
            <img className="w-8 px-16" src={bloomberg} alt="bloomberg" />
            <img
              className="w-8 px-16"
              src={cointelegraph}
              alt="cointelegraph"
            />
            <img className="w-8 px-16" src={reuters} alt="reuters" />
            <img className="w-8 px-16" src={yahoo} alt="yahoo" />
            <img className="w-8 px-16" src={forbes} alt="forbes" />
            <img className="w-8 px-16" src={wired} alt="wired" />
          </Slider>
        </div>

        <div className="absolute bottom-0 px-3 text-center left-4 text-md">
          {locale === "es"
            ? "*las estadísticas presentadas en esta sección corresponden a programas similares que hemos ejecutado en el pasado a partir de 2019."
            : "*the statistics presented in this section correspond to similar programs that we have ran in the past starting from 2019."}
        </div>
      </div>

      {/* <div className="absolute bottom-3 right-3">
        <p className="text-sm">Powered by:</p>
        <img src={threecommas} alt="" />
      </div> */}
    </div>
  );
}

export default Milestone;
