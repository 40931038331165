import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import UploadIcon from "../assets/icons/upload.svg";
import { toast } from "react-toastify";
import { useLocalStorage } from ".";
import { UploadFile } from "../API";

export const DragToUpload = ({ startLoading, stopLoading, setFile }) => {
  const [authToken, setAuthToken] = useLocalStorage("authToken", "");
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setUploadedFiles(acceptedFiles);
    },
  });

  useEffect(() => {
    if (uploadedFiles.length > 0) {
      upload();
    }
  }, [uploadedFiles]);

  const upload = async () => {
    startLoading();
    let formData = new FormData();
    formData.append("file", uploadedFiles[0]);
    const res = await UploadFile(formData, authToken);
    if (res?.success) {
      setFile(res.url);
      toast.promise("Proceed to save your changes", { position: "top-center" });
    } else {
      toast.error(res.message, { position: "top-center" });
    }
    stopLoading();
  };

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <div className="flex flex-col items-center gap-3">
        <div className="w-6 h-6 cursor-pointer">
          <img src={UploadIcon} className="w-full h-full" alt={"upload-icon"} />
        </div>

        <div className="flex gap-1 items-center">
          <div className="cursor-pointer">
            <span className="font-semibold text-md text-primary-700">
              Click to upload
            </span>
          </div>
          <span className="font-semibold text-md text-grey-200">
            or drag and drop
          </span>
        </div>
        <ul>
          {uploadedFiles.map((file) => (
            <li key={file.name}>{file.name}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

// Dropdown.js

export const Dropdown = ({ selected, options, onChange, style }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleOptionClick = (option) => {
    //console.log(option);
    onChange(option);
    setIsOpen(false); // Close dropdown after selecting an option
  };

  return (
    <div className="relative inline-block text-left">
      <button
        onClick={toggleDropdown}
        className={`text-white px-4 py-2 rounded-md focus:outline-none ${style}`}
      >
        {selected || ""}
      </button>
      {isOpen && (
        <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg">
          <ul className="py-1">
            {options.map((item) => (
              <li key={item.label}>
                <button
                  onClick={() => handleOptionClick(item.value)}
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100 w-full text-left"
                >
                  {item.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};
