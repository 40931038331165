import dayjs from "dayjs";
import { useLocalStorage } from "../hooks";

const months = [
  { id: "1", label: "Jan" },
  { id: "2", label: "Feb" },
  { id: "3", label: "Mar" },
  { id: "4", label: "Apr" },
  { id: "5", label: "May" },
  { id: "6", label: "Jun" },
  { id: "7", label: "Jul" },
  { id: "8", label: "Aug" },
  { id: "9", label: "Sep" },
  { id: "10", label: "Oct" },
  { id: "11", label: "Nov" },
  { id: "12", label: "Dec" },
];

export const AmountSeparator = (amount, decimal) => {
  const separate = (amount) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  const rounding = (Math.round(amount * 100) / 100).toFixed(decimal || 2);
  const cal = separate(rounding);
  return cal;
};

export const formatDate = (date, format) => dayjs(date).format(format); //format sample MMM DD, YYYY. h:mm a

export const maskWalletAddress = (address) => {
  if (!address) return "";
  const visibleLength = 6; // Number of visible characters at the beginning and end of the address
  const maskedChars = "...";
  return `${address.slice(0, visibleLength)}${maskedChars}${address.slice(
    -visibleLength
  )}`;
};

export const btcData = {
  strategy: [
    { name: "CB002", return: "1.5%", direction: "LONG", leverage: "1.0x" },
    { name: "CB003", return: "2.25%", direction: "LONG", leverage: "1.5x" },
    { name: "CB004", return: "1.5%", direction: "LONG", leverage: "1.0x" },
    { name: "CB005", return: "2.25%", direction: "LONG", leverage: "1.5x" },
  ],
};

export const ethData = {
  strategy: [
    { name: "CB007", return: "2.0%", direction: "LONG", leverage: "1.0x" },
    { name: "CB008", return: "3.0%", direction: "LONG", leverage: "1.5x" },
    { name: "CB009", return: "2.0%", direction: "LONG", leverage: "1.0x" },
    { name: "CB010", return: "3.0%", direction: "LONG", leverage: "1.5x" },
  ],
};

export const solData = {
  strategy: [
    // { name: "CB012", return: "2.0%", direction: "LONG", leverage: "1.0x" },
    { name: "CB013", return: "3.0%", direction: "LONG", leverage: "1.5x" },
    { name: "CB014", return: "2.0%", direction: "LONG", leverage: "1.0x" },
    { name: "CB015", return: "3.0%", direction: "LONG", leverage: "1.5x" },
  ],
};

export const usdtData = {
  strategy: [
    { name: "CB016", return: "1.5%", direction: "SPOT", leverage: "1.0x" },
    { name: "CB018", return: "8.0%", direction: "SPOT", leverage: "1.5x" },
  ],
};

export const formatDay = (input, index) => {
  const d = input ? new Date(input) : new Date();
  const year = d.getFullYear(); // 2019

  const monthIndex = d.getMonth();
  const monthName = months[monthIndex];
  const result = index ? monthIndex : `${monthName.label} ${year}`;
  return result;
};

export const confirmSignIn = async (message, window, setAuthToken) => {
  if (message == "Unauthorized") {
    window.open("/dashboard", "_self");
    setAuthToken("");
  }
};

export const BOT_INTENT = Object.freeze({
  START: "START",
  SOFT_STOP: "SOFT_STOP",
  HARD_STOP: "HARD_STOP",
  RESUME: "RESUME",
  FULFILLED: "FULFILLED",
});

export const calculateTimeDuration = (startTime, endTime) => {
  // Convert the ISO 8601 string to Date objects
  const startDate = new Date(startTime);
  const endDate = new Date(endTime);

  // Calculate the difference in milliseconds
  const diffMs = endDate - startDate;

  // Calculate the hours, minutes, and seconds from the difference
  const diffHours = Math.floor(diffMs / (1000 * 60 * 60));
  const diffMinutes = Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60));
  const diffSeconds = Math.floor((diffMs % (1000 * 60)) / 1000);

  return { hours: diffHours, minutes: diffMinutes, seconds: diffSeconds };
};
