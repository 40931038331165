import React, { useEffect, useState } from "react";
import Wrapper from "../Dashboard/DashboardWrapper";
import { Bar, Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { CategoryScale } from "chart.js";
import MainWrapper from "../MainWrapper";
import Sidebar from "../Dashboard/Sidebar";
import Header from "../Dashboard/Header";
import { toast } from "react-toastify";
import { useLoading, useLocalStorage } from "../../hooks";
import { FetchBotProfits, FetchBotStats } from "../../API";
import { useLocation } from "react-router-dom";
import { confirmSignIn } from "../../utilities";
import front_arrow from "../../assets/icons/front-arrow.png";

Chart.register(CategoryScale);

function Stats() {
  const [showSideBar, setShowSideBar] = useState(false);
  const [isLoading, startLoading, stopLoading] = useLoading();
  const [authToken, setAuthToken] = useLocalStorage("authToken", "");
  const [stats, setStats] = useState({});
  const [chartData, setChartData] = useState({
    labels: [].map((data) => data?.date),
    datasets: [
      {
        label: "Summary profit",
        data: [].map((data) => data?.usd_profit),
        backgroundColor: ["#50AF95"],
        borderColor: "#50AF95",
        borderWidth: 2,
      },
    ],
  });

  const location = useLocation();

  // Parse the search string to extract query parameters
  const queryParams = new URLSearchParams(location.search);

  // Get individual query parameters by key
  const strategy = queryParams.get("strategy");
  const bot_id = queryParams.get("bot_id");
  const exchange = queryParams.get("exchange");
  const profit_coin = queryParams.get("profit_coin");
  const deposit_coin = queryParams.get("deposit_coin");

  const fetchBotProfit = async () => {
    startLoading();
    const res = await FetchBotProfits(authToken, bot_id);
    console.log(res);
    if (res?.success) {
      setChartData({
        labels: res.profits.map((data) => data?.date),
        datasets: [
          {
            label: "Summary profit (USD)",
            data: res.profits.map((data) => data?.usd_profit),
            backgroundColor: ["#50AF95"],
            borderColor: "#50AF95",
            borderWidth: 2,
          },
        ],
      });
    } else {
      confirmSignIn(res.message, window, setAuthToken);
      toast.error(res?.message || "Unable to fetch data", {
        position: "top-center",
      });
    }
    stopLoading();
  };

  const fetchBotStats = async () => {
    startLoading();
    const res = await FetchBotStats(authToken, bot_id);
    console.log(res);
    if (res?.success) {
      setStats(res.stats);
    } else {
    }
    stopLoading();
  };

  useEffect(() => {
    fetchBotProfit();
    fetchBotStats();
  }, []);

  return (
    <MainWrapper>
      <Sidebar
        showSideBar={showSideBar}
        setShowSideBar={setShowSideBar}
        active={{ id: "2", href: "/dashboard/bot-active" }}
      />
      <Header showSideBar={showSideBar} setShowSideBar={setShowSideBar} />
      <Wrapper className={"px-4 py-4 rounded-md relative bg-[#292C3C] "}>
        <div
          
          className="flex items-center gap-4 py-4 mb-10 sm:mt-0"
        >
          <img onClick={() => window.open(`/dashboard/bot-active`, "_self")} src={front_arrow} className="w-8 rotate-[180deg] cursor-pointer" />
          <span className="font-semibold">All bots</span>
        </div>
        <div className="sm:w-[50%] mt-8 sm:mt-0">
          <div className="bg-[#151924] py-8 px-6 rounded-xl">
            <h2 className="font-semibold text-xl border-b-[0.5px]">
              Bot Stats
            </h2>
            <Line
              data={chartData}
              options={{
                plugins: {
                  title: {
                    display: true,
                    text: "",
                  },
                },
              }}
            />
          </div>
          {/* <div className="bg-[#151924] py-8 px-6 rounded-xl mt-6">
              <Bar
                data={chartData2}
                options={{
                  plugins: {
                    title: {
                      display: true,
                      text: "Profit by day",
                    },
                  },
                }}
              />
            </div> */}
        </div>

        {/* <div className="bg-[#151924] py-8 px-6 rounded-xl overflow-scroll max-h-screen hide-scrollbar">
            <div className="mb-4 text-xl">Bot events</div>

            {Array.from({ length: 50 }, (_, index) => index + 1).map((item) => (
              <div
                key={item}
                className="py-3 border-b-[0.5px] text-blue-100 text-md"
              >
                Bot 12662663 Starting new deal. Pair USDT_BTC.
              </div>
            ))}
          </div> */}

        <div className="sm:mt-6 mt-8 bg-[#151924] py-4 px-4 sm:w-[50%] rounded-xl ">
          <div className="font-semibold text-lg border-b-[0.5px] border-border">
            Stats
          </div>
          <div className="flex flex-col gap-1 mt-2 text-sm border-[0.5px] border-border ">
            <div className="flex justify-between items-center border-b-[0.5px] border-border py-1 px-2">
              <span className="opacity-70">Completed deals</span>
              <span className="opacity-70">{stats.completed}</span>
            </div>
            <div className="flex justify-between items-center border-b-[0.5px] border-border py-1 px-2">
              <span className="opacity-70">Active deals</span>
              <span className="opacity-70">{stats.active}</span>
            </div>
            <div className="flex justify-between items-center border-b-[0.5px] border-border py-1 px-2">
              <span className="opacity-70">Active deals profit (USD)</span>
              <span className="opacity-70">
                {stats.active_deals_usd_profit}
              </span>
            </div>
            <div className="flex justify-between items-center border-b-[0.5px] border-border py-1 px-2">
              <span className="opacity-70">Completed deals profit (USD)</span>
              <span className="opacity-70">
                {stats.completed_deals_usd_profit}
              </span>
            </div>
            <div className="flex justify-between items-center border-b-[0.5px] border-border py-1 px-2">
              <span className="opacity-70">
                Funds locked in Active deals (USD)
              </span>
              <span className="opacity-70">
                {stats.funds_locked_in_active_deals}
              </span>
            </div>
            {/* <div className="flex justify-between items-center border-b-[0.5px] border-border py-1 px-2">
              <span className="">UNPL of active Bot deals</span>
              <span className="">1</span>
            </div> */}
          </div>
        </div>

        <div className="mt-4 text-md">
          <a
            href={`/dashboard/bot-stats/pair?strategy=${strategy}&bot_id=${bot_id}&profit_coin=${profit_coin}&deposit_coin=${deposit_coin}&exchange=${exchange}`}
            target="_self"
          >{`Open current chart trade chart >`}</a>
        </div>
      </Wrapper>
    </MainWrapper>
  );
}

export default Stats;
